import React, { useEffect } from "react";
import Lottie from "lottie-react";

import success from "../../../assets/Dexlabz/payment_success.json";
import { useNavigate } from "react-router-dom";

function PaymentSuccess() {
	const navigate = useNavigate();

	useEffect(() => {
		const delayedEffect = setTimeout(() => {
			navigate("/");
		}, 5000);

		return () => clearTimeout(delayedEffect);
	}, []);

	return (
		<div className="flex flex-col justify-center items-center h-screen ">
			<Lottie
				animationData={success}
				loop={false}
				width={"50px"}
				height={"50px"}
				className="w-[400px] h-[400px] md:w-[500px] md:h-[500px] "
			/>

			<p className="-translate-y-32 md:-translate-y-44 font-semibold text-green-500">
				Payment SuccessFully
			</p>
		</div>
	);
}

export default PaymentSuccess;
