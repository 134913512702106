import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDarkModeEnabled: false,
  nowPlaying: {},
  currentChapter: {},
  showGlobalProgressIndicator:false,
};

const appSlice = createSlice({
  name: "appReducer",
  initialState,
  reducers: {
    darkMode(state, action) {
      state.isDarkModeEnabled = action.payload;
    },
    nowPlaying(state, action) {
      state.nowPlaying = action.payload;
    },
    currentVideo(state, action) {
      state.currentChapter = action.payload;
    },
    showGlobalProgressIndicator(state, action) {
      state.showGlobalProgressIndicator = action.payload;
    },
  },
});

export const { darkMode, nowPlaying, currentVideo, showGlobalProgressIndicator } =
  appSlice.actions;
export default appSlice.reducer;
