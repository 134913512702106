import React from "react";
import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";
import { IoCloseSharp } from "react-icons/io5";

function PdfViewer({ pdfUrl, rightPopup, setShowPdfFile }) {
  // Create Tailwind instance
  const tw = createTw({
    theme: {
      fontFamily: {
        sans: ["Comic Sans MS", "sans-serif"], // Use fallback fonts if necessary
      },
      extend: {
        colors: {
          custom: "#bada55",
        },
      },
    },
  });

  // Custom styles
  const styles = StyleSheet.create({
    page: { backgroundColor: "" },
    section: { color: "", textAlign: "center", margin: 30 },
  });

  return (
    <div className="w-screen h-screen bg-[rgba(128,128,128,.8)] flex justify-end items-center fixed top-0 left-0 z-20 cursor-default  ">
      <div
        className={`bg-white  lg:w-[40rem] h-[100vh] tablet:w-[60%]    shadow-lg relative ${rightPopup} `}
      >
        <div className="p-5 w-full flex justify-between  ">
          <p className="font-bold"> </p>
          <IoCloseSharp
            className={` text-3xl text cursor-pointer hover:text-gray-700 text-gray-600 `}
            onClick={() => setShowPdfFile(false)}
          />
        </div>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text>Section #1</Text>
            </View>
          </Page>
        </Document>
      </div>
    </div>
  );
}

export default PdfViewer;
