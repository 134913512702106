import React from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import DarkFooter from "../Footer/DarkFooter";

function Tnc() {
  const userAccount = [
    {
      title:
        "In order to access certain features of the Platform, you maybe required to create an account. You must provide accurate and complete information when creating an account. You are solely responsible for maintaining the confidentiality of    your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.",
    },
    {
      title:
        "You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.",
    },
    {
      title:
        "We reserve the right to suspend or terminate your account at any time without prior notice if we suspect any unauthorized or fraudulent activity associated with your account.",
    },
  ];
  const content = [
    {
      title:
        "The content provided on UpMyPrep.com, including but not limited to educational materials, text, graphics, images, videos, and interactive features, is for informational and educational purposes only. We do not guarantee the accuracy, completeness, or usefulness of any content available on the Platform.",
    },
    {
      title:
        "Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works based on, distribute, perform, display, or in any way exploit any of the content, software, materials, or services available on the Platform.",
    },
    {
      title:
        "We reserve the right to remove any content posted by users that we deem inappropriate, offensive, or in violation of these terms and conditions.",
    },
  ];
  const payment = [
    {
      title:
        "Some features of the Platform may require payment of fees. By selecting a paid feature, you agree to pay the applicable fees as described on the Platform.",
    },
    {
      title: "All fees are non-refundable unless otherwise stated.",
    },
    {
      title:
        "We reserve the right to change our fee structure and payment terms at any time. Such changes will be effective immediately upon posting on the Platform.",
    },
  ];
  const privacy = [
    {
      title:
        "Your use of the Platform is subject to our Privacy Policy, which governs the collection, use, and disclosure of your personal information. By using the Platform, you consent to the terms of our Privacy Policy.",
    },
  ];
  const intellectual = [
    {
      title:
        " The Platform and its original content, features, and functionality are owned by PhynLabz Tech Pvt. Ltd. and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
    },
    {
      title:
        " You may not modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Platform or any portion thereof.",
    },
  ];
  const disclaimer = [
    {
      title:
        ' The Platform is provided on an "as-is" and "as available" basis without any warranties of any kind, express or implied. We disclaim all warranties, including but not limited to merchantability, fitness for a particular purpose, non-infringement, or accuracy.',
    },
  ];
  const limitation = [
    {
      title:
        " In no event shall PhynLabz Tech Pvt. Ltd., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Platform; (ii) any conduct or content of any third party on the Platform; (iii) any content obtained from the Platform; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.",
    },
  ];
  const governingLaw = [
    {
      title:
        " These terms and conditions shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law provisions.",
    },
  ];
  const changesToTerms = [
    {
      title:
        " We reserve the right, at our sole discretion, to modify or replace these terms and conditions at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
    },
  ];
  const contact = [
    {
      title:
        " If you have any questions about these terms and conditions, please contact us at [insert contact information].",
    },
  ];
  return (
    <div className="">
      <Toolbar />

      <div className="flex justify-center flex-col  items-center   ">
        <div className="w-[90%]  lg:w-[70%] tablet:w-[90%]  flex flex-col gap-10   tablet:py-3 items-start my-10 lg:px-10  ">
          <div className="flex justify-center  w-full text-2xl font-semibold  ">
            <p className="">Terms & Conditions </p>
          </div>{" "}
          <div className="flex flex-col gap-5  w-full   ">
            <p className="font-medium border-y-2 py-5 text-sm md:text-base ">
              Please read these terms and conditions carefully before using
              UpMyPrep.com (referred to as "the Platform" or "UpMyPrep"), a
              digital education platform provided by PhynLabz Tech Pvt. Ltd.
              (referred to as "the Company" or "we/us/our"). Your access to and
              use of the Platform is conditioned on your acceptance of and
              compliance with these terms. By accessing or using the Platform,
              you agree to be bound by these terms. If you disagree with any
              part of the terms, then you may not access the Platform.{" "}
            </p>
            <ul className="list-decimal px-5 flex flex-col gap-5">
              <li className="font-medium pb-5 text-sm md:text-base ">
                User Accounts
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {userAccount?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Content
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {content?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Payment and Fees
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {payment?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Privacy
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {privacy?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Intellectual Property
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {intellectual?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Disclaimer{" "}
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {disclaimer?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Limitation of Liability
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {limitation?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Governing Law
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {governingLaw?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>{" "}
              <li className="font-medium pb-5 text-sm md:text-base">
                Changes to Terms
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {changesToTerms?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="font-medium pb-5 text-sm md:text-base">
                Contact Us
                <div className="h-[2px] bg-black w-[3rem] mt-1"></div>
                <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 ">
                  {contact?.map((items, index) => (
                    <li key={index} className="">
                      {items?.title}
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <p className="font-medium border-y-2 py-5 text-sm md:text-base">
              By accessing or using the Platform, you agree to be bound by these
              terms and conditions. If you do not agree to these terms and
              conditions, you may not access or use the Platform.
            </p>
          </div>
        </div>
      </div>

      <DarkFooter />
    </div>
  );
}

export default Tnc;
