import React from "react";

import img1 from "../../../assets/Dexlabz/s1.svg";
import img2 from "../../../assets/Dexlabz/s2.svg";
import img3 from "../../../assets/Dexlabz/s3.svg";

function ServicesContainer() {
	const servicesCard = [
		{
			img: img1,
			heading: "Daily live classes",
			dics: "Chat with educators, ask questions, answer live polls, and get your doubts cleared - all while the class is going on",
		},
		{
			img: img2,
			heading: "Practice and revise",
			dics: "Learning isn't just limited to classes with our practice section, mock tests and lecture notes shared as PDFs for your revision",
		},
		{
			img: img3,
			heading: "Learn anytime, anywhere",
			dics: "One subscription gets you access to all our live and recorded classes to watch from the comfort of any of your devices        ",
		},
	];

	return (
		<div>
			<div className=" flex justify-center mx-3 my-10 tablet:mx-10 ">
				<div className="w-full mx-2 lg:w-[70%] 2sm:w-[90%] 2sm:flex-row  flex justify-between flex-col lg:flex-row tablet:flex-row flex-wrap">
					{servicesCard.map((data, index) => (
						<div
							className="w-full md:w-[30%] 2sm:w-[45%]  mx-auto flex flex-col my-5"
							key={index}
						>
							<div className=" my-5">
								<img src={data.img} alt="" className=" h-full w-full" />
							</div>
							<p className="font-bold text-2xl  ">{data.heading}</p>
							<p className="">{data.dics}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default ServicesContainer;
