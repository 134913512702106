import React from "react";
import Lottie from "lottie-react";

import lottieAnimation from "../../../assets/Dexlabz/AfterLoginUnauthorise.json";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Shared/CustomButton/CustomButton";

function AfterLoginUnauthorise() {
	const navigate = useNavigate();

	return (
		<div className="flex justify-center items-center h-screen flex-col ">
			<Lottie
				animationData={lottieAnimation}
				loop={true}
				className="w-[95%] md:w-[70%] h-[40%] md:h-[70vh] "
			/>
			<CustomButton
				lable={"Go Home"}
				className={`bg-green-500 text-white border-none rounded-md hover:bg-green-600 !text-lg md:!text-base`}
				onClick={() => navigate("/")}
			/>
		</div>
	);
}

export default AfterLoginUnauthorise;
