import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiConstants } from "../api/ApiConstants";

const initialState = {
  userGoalInfo: {},
  loading: false,
  error: false,
  errMsg: "",
};

export const UserSelectGoalThunk = createAsyncThunk(
  "user/select-goal",
  (accessToken) => {
    // console.log("token UserSelectGoalThunk me ", accessToken);

    return axios
      .get(ApiConstants.BASE_URL + "" + ApiConstants.USER_SELECT_GOAL, {
        headers: {
          "auth-token": accessToken,
        },
      })
      .then((res) => {
        // console.log("UserSelectGoalThunk me goal api ka data : ", res.data);
        return res.data.serverResponse.userInfo;
      })
      .catch((err) => console.log(err));
  }
);

const UserSelectGoalSlice = createSlice({
  name: "userSelectGoalReducer",
  initialState,

  reducers: {
    setUserGoalInfo: (state, action) => {
      // console.log("UserSelectGoalThunk ke andar new reducer ka data : ", action.payload);
      state.userGoalInfo.activeGoal._id = action.payload.id;
      state.userGoalInfo.activeGoal.goalTitle = action.payload.goalTitle;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(UserSelectGoalThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserSelectGoalThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.userGoalInfo = action.payload;
      state.error = "";

    });
    builder.addCase(UserSelectGoalThunk.rejected, (state, action) => {
      state.loading = false;
      state.userGoalInfo = [];
      state.error = action.error.message;
    });
  },
});
export const { setUserGoalInfo } = UserSelectGoalSlice.actions;

export default UserSelectGoalSlice.reducer;
