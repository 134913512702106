import React, { useContext, useEffect, useState } from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import LightFooter from "../Footer/LightFooter";
import { AuthContext } from "../../../providers/AuthProvider";
import defaultAvatar from "../../../assets/Dexlabz/awtar.png";

function Profile({}) {
	const { userInfo } = useContext(AuthContext);

 const userAvatarUrl = userInfo?.avatar?.url;

 const handleImageError = (event) => {
   event.target.src = defaultAvatar;
 };

	return (
    <div>
      <Toolbar />

      <div className="flex justify-center">
        <div className=" w-[95%] md:w-[70%] my-5 rounded-lg py-5 shadow-lg ">
          <div className="flex  items-start  px-5 md:px-0">
            <div className=" flex justify-center w-[30%] md:w-[20%]">
              {userInfo?.avatar?.url ? (
                <img
                  src={userAvatarUrl || defaultAvatar}
                  alt="User Avatar"
                  className=" rounded-full "
                  onError={handleImageError}
                />
              ) : (
                <img
                  src={defaultAvatar}
                  alt="User Avatar"
                  className=" rounded-full "
                />
              )}
            </div>
            <div className="ms-5 md:ms-0">
              <p className="text-xl md:text-3xl font-bold text-gray-700">
                {`${userInfo?.firstName} ${userInfo?.lastName}`}
              </p>
              <p className=" text-xsm md:text-sm text-stone-400 mt-2">
                Joined UpMyPrep in Oct 2023
              </p>
            </div>
          </div>

          <div className="flex text-stone-400 mt-5 text-xs md:text-sm md:ps-10">
            <p className="mx-auto md:mx-5 flex  flex-col md:flex-row md:items-baseline  ">
              <b className="text-lg md:text-2xl font-serif text-stone-600 md:me-3">
                104{" "}
              </b>
              Watch mins
            </p>
            <p className="mx-auto md:mx-10 flex  flex-col md:flex-row md:items-baseline ">
              <b className="text-lg md:text-2xl font-serif text-stone-600 md:me-3">
                6{" "}
              </b>{" "}
              Following
            </p>
            <p className="mx-auto md:mx-10 flex  flex-col md:flex-row md:items-baseline ">
              <b className="text-lg md:text-2xl font-serif text-stone-600 md:me-3">
                3{" "}
              </b>{" "}
              Knowledge Hats
            </p>
          </div>
        </div>
      </div>

      <LightFooter />
    </div>
  );
}

export default Profile;
