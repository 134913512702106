import axios from "axios";
import { ApiConstants } from "../api/ApiConstants";

const axiosConfig = axios.create({
  baseURL: ApiConstants.BASE_URL,
  timeout: 1000,
});
export const RequestType = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

export const NetworkCall = async (type, endpoint, body = {}, token = null) => {
  const config = {
    "auth-token": token,
  };
  switch (type) {
    case RequestType.GET:
      return await axiosConfig.get(endpoint, config);
    case RequestType.POST:
      return await axiosConfig.post(endpoint, body, config);
    case RequestType.PUT:
      return await axiosConfig.put(endpoint, body, config);
  }
};
