import React from "react";
import CustomButton from "../../../Shared/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

function PickLanguage({
	animationClass,
	setShowLanguageBox,
	subGoalList,
	currentIndex,
}) {
	// console.log(
	//   "pickLanguage me SubGolaList ki value : ",
	//   subGoalList[0].language
	// );

	return (
		<div
			className={`w-[70%] lg:w-[30%] tablet:w-[50%] bg-blue-200   min-h-full md:h-screen  absolute right-0 top-0  transition-all duration-700  ${animationClass}`}
		>
			<AiOutlineClose
				className="absolute text-3xl right-3 top-3 md:right-10 md:top-5 cursor-pointer  p-1.5 rounded-full bg-gray-400 text-gray-800"
				onClick={() => setShowLanguageBox(false)}
			/>
			<div className=" p-5 pt-10  md:p-10">
				<div className=" mb-10">
					<p className="text-xl tablet:text-2xl lg:text-4xl font-bold mb-3">
						Pick a goal Language
					</p>
					<p className="text-xs md:text-sm ">
						Courses in the preferred language will be shown first
					</p>
				</div>

				<div className="flex flex-col md:flex-row flex-wrap justify-between">
					{subGoalList[currentIndex]?.language?.map((language, index) => (
						<Link
							to=""
							className="w-full md:w-[45%] h-[7rem] md:h-[13rem] bg-[rgba(255,246,223)] relative rounded-lg mt-5"
							key={index}
						>
							<CustomButton
								lable={language?.languageName}
								className={`mt-5 border-none !text-xl`}
							/>
							<img
								src={require("../../../../../assets/Dexlabz/English.png")}
								className="absolute bottom-5 right-0 w-[50%]"
								alt=""
							/>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
}

export default PickLanguage;
