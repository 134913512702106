import React, { useRef } from "react";

import hero from "../../../assets/Dexlabz/hero-bg.svg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { Carousel } from "react-responsive-carousel";

import img1 from "../../../assets/Dexlabz/center-1.avif";
import img2 from "../../../assets/Dexlabz/center-2.avif";
import img3 from "../../../assets/Dexlabz/center-3.avif";
import img4 from "../../../assets/Dexlabz/center-6.avif";
import img5 from "../../../assets/Dexlabz/center-5.avif";

function UnacademyHeroContainer() {
  const ref = useRef();
  const showNext = () => {
    ref.current.onClickNext();
  };

  const showRevious = () => {
    ref.current.onClickPrev();
  };

  const imageUrls = [
    {
      url: img1,
    },
    {
      url: img2,
    },
    {
      url: img3,
    },
    {
      url: img4,
    },
    {
      url: img5,
    },
  ];
  return (
    <div className="flex items-center justify-center my-1 md:my-5 animate-blurIn">
      <div className="absolute flex w-[87%] tablet:w-[80%] lg:w-[78%] justify-between mx-auto left-0 right-0 z-30 ">
        <button className="p-2 rounded-2xl" onClick={showRevious}>
          <img
            src={require("../../../assets/Dexlabz/prev-icon.png")}
            alt=""
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>
        <button className="p-2 rounded-2xl " onClick={showNext}>
          <img
            src={require("../../../assets/Dexlabz/next-icon.png")}
            alt=""
            className="w-[50%] tablet:w-[75%] lg:w-[90%]"
          />
        </button>

        {/* <button className="p-2 rounded-2xl" onClick={showRevious}><AiFillLeftSquare className="text-gray-400 text-3xl md:text-5xl" /></button>
        <button className="p-2 rounded-2xl" onClick={showNext}><AiFillRightSquare className="text-gray-400 text-3xl md:text-5xl" /></button> */}
      </div>

      {/* <div className=""> */}
      <Carousel
        ref={ref}
        centerMode={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        showIndicators={false}
        className="rounded-md"
        centerSlidePercentage={75}
      >
        {imageUrls?.map((image, index) => (
          <img
            key={index}
            className=" md:rounded-xl  w-[80%] tablet:w-[90%] h-[7rem] tablet:h-[15rem] md:h-[22rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 rounded-md"
            src={image?.url}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default UnacademyHeroContainer;
