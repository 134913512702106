import { BrowserRouter as Router } from "react-router-dom";
import { getTheme } from "./utils/helper";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiConstants } from "../src/api/ApiConstants.jsx";

import Slider from "./pages/ UnacademyPages/UnacademyComponents/Slider";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthProvider } from "./providers/AuthProvider";
import LoadingPage from "./pages/ UnacademyPages/LoadingPage/LoadingPage";
import Loader from "./pages/ UnacademyPages/Loader/Loader";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { DashBoardApi } from "./api/ReduxToolkit.jsx";
import { Offline, Online } from "react-detect-offline";
import OfflinePopup from "./pages/ UnacademyPages/OfflinePopup/OfflinePopup.jsx";
function App() {
  const [DefaultRouter, setRouter] = useState(null);

  useEffect(() => {
    getCurrentTheme();
  }, []);
  const getCurrentTheme = async () => {
    const { data } = await axios.get(
      ApiConstants.BASE_URL + ApiConstants.GET_DEFAULT_THEME + "/8604681087"
    );
    setRouter(getTheme(data));
  };

  return (
    <div>
      <Online>
        <ApiProvider api={DashBoardApi}>
          <AuthProvider>
            <Provider store={store}>
              <Router>{DefaultRouter ? DefaultRouter : <LoadingPage />}</Router>
            </Provider>
          </AuthProvider>
        </ApiProvider>
      </Online>
      <Offline>
        <OfflinePopup />
      </Offline>
    </div>
  );
}

export default App;
