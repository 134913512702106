import React from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import img from "../../../assets/Dexlabz/quote.svg";
import { FaCirclePlay } from "react-icons/fa6";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Utilits } from "../../../utils/utils";

function IntroContainer() {
  const feedback = [
    {
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta corporis ut modi distinctio beatae mollitia perferendis sequi animi aspernatur, consequuntur itaque fugiat, similique commodi hic. Aliquam possimus quo non inventore!",
      name: "krishna kumar",
    },
    {
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta corporis ut modi distinctio beatae mollitia perferendis sequi animi aspernatur, consequuntur itaque fugiat, similique commodi hic. Aliquam possimus quo non inventore!",
      name: "puja singh rathor",
    },
    {
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta corporis ut modi distinctio beatae mollitia perferendis sequi animi aspernatur, consequuntur itaque fugiat, similique commodi hic. Aliquam possimus quo non inventore!",
      name: "krishna gautam",
    },
    {
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta corporis ut modi distinctio beatae mollitia perferendis sequi animi aspernatur, consequuntur itaque fugiat, similique commodi hic. Aliquam possimus quo non inventore!",
      name: "vipul savita",
    },
    {
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta corporis ut modi distinctio beatae mollitia perferendis sequi animi aspernatur, consequuntur itaque fugiat, similique commodi hic. Aliquam possimus quo non inventore!",
      name: "Ankit Soni kumar",
    },
  ];
  return (
    <div>
      <div className=" flex justify-center mx-3 my-10 tablet:mx-10 bg-[#F6F9FA] py-10">
        <div className="w-full mx-2 lg:w-[70%] 2sm:w-[90%]  flex justify-between flex-col ">
          <p className="text-2xl font-semibold mb-10">
            How learners like you are achieving their goals
          </p>

          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=" w-full self-center z-0"
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 3.2,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1.1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2.2,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {feedback.map((card, index) => (
              <div className="  p-5 me-5 border shadow-md" key={index}>
                <img src={img} alt="" className="w-[2rem]" />
                <p className=" my-5">{card?.description}</p>

                <div className="flex items-start border-b pb-5 ">
                  <p className="bg-[#313639] p-2 text-white rounded-full text-sm me-3">
                    {Utilits.formateName(card?.name)}
                  </p>
                  <p className="font-medium capitalize">{card?.name}</p>
                </div>
                <div className="flex items-center pt-5 text-[#5624D0]">
                  <FaCirclePlay className=" text-3xl" />
                  <p className="text-sm font-medium mx-3 line-clamp-2">
                    Ultimate AWS Certified Cloud Practitioner CLF-C02
                  </p>
                </div>
              </div>
            ))}
          </Carousel>

          {/* <div className="w-full lg:w-[50%] h-full">
            <img
              src={require("../../../assets/Dexlabz/classroom-2.png")}
              alt=""
              className=" h-full rounded-md"
            />
          </div>

          <div className="flex items-start justify-start flex-col lg:mx-10 w-full mt-5 lg:w-[40%] text-sm">
            <p className="font-bold text-sm text-blue-500">INTRODUCING</p>
            <p className="font-bold text-2xl my-5 w-[80%]">
              UpMyPrep Centres for IIT JEE and NEET UG
            </p>
            <p className="w-[80%]">
              Admissions open in Kota, Delhi, Chandigarh, Sikar, Indore and 20+
              other cities!
            </p>

            <ul className="list-disc px-5 my-5">
              <li className=" my-2">Learn from top educators in your city</li>
              <li className=" my-2">In-person classes & doubt solving</li>
              <li className=" my-2">Bonus access to online learning</li>
            </ul>

            <CustomButton
              lable={"Find a center"}
              className={`bg-gray-700 border-gray-700 hover:border-gray-800 text-white hover:bg-gray-800 !px-8 !text-xs`}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default IntroContainer;
