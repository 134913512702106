import CustomButton from "../Shared/CustomButton/CustomButton";

const EnableRating = ({ liveCompetitor }) => {
  return (
    <div className="flex justify-center items-center py-10 w-full  bg-sky-50">
      <div className="flex flex-col-reverse tablet:flex-row md:flex-row justify-between items-center lg:items-start rounded-md w-full tablet:w-[90%] lg:w-[70%] p-5 z-20 bg-neutral-100">
        <div className="flex flex-col w-full mt-8 md:mt-0 md:w-[50%] tablet:w-[80%]  ">
          <p className="leading-tight font-semibold text-lg md:text-2xl p-1 text-[#313639]">
            Compete to unlock your rating
          </p>
          <p className="leading-relaxed text-sm w-[100%] px-2">
            {liveCompetitor} learners competing
          </p>
          <div className="flex  items-center  tablet:w-[90%] mt-10">
            <CustomButton
              className={
                "bg-gray-700 text-white  border-none tablet:text-sm !mx-0 "
              }
              lable={"Compete Now"}
            />
            <CustomButton
              className={
                "bg-neutral-200 hover:bg-neutral-300   border-none tablet:text-sm "
              }
              lable={"Learn More"}
            />
          </div>
        </div>
        <div className="w-[50%]  flex justify-end">
          <img
            src="https://static.uacdn.net/production/_next/static/images/compete/compete-to-unlock-rating.svg?q=75&auto=format%2Ccompress&w=640"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default EnableRating;
