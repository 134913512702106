import { Route, Routes } from "react-router-dom";
import { useContext } from "react";
// import { AuthContext } from "../ContextProvider/AuthProvider";


export const AdminRouter = () => {
  // const { accessToken } = useContext(AuthContext);

  // return accessToken !== null && accessToken !== undefined ? (
  //   <Routes>
  //     {/* <Route path="/" Component={AdminComponent}>
  //       <Route path="/kanban" Component={Kanban} />
  //       <Route path="/analytics" Component={Analytics} />
  //       <Route path="/notifications" Component={Notification} />
  //       <Route path="/settings" Component={Settings} />
  //       <Route path="/manage-app" Component={ManageApp} />
  //     </Route> */}
  //   </Routes>
  // ) : (
  //   <Routes>
  //     {/* <Route path="*" Component={AdminAuthenticate} /> */}
  //   </Routes>
  // );
};
