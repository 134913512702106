import React from "react";
import { useNavigate } from "react-router-dom";

function CourseCard({ data }) {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col min-w-[10rem] lg:min-w-[10rem] mx-3  p-1 rounded-md border border-gray-50 m-1 hover:bg-gray-100 cursor-pointer"
      onClick={() => navigate("/player")}
    >
      {/* <img src={data["image"]} className="rounded-md min-h-[12rem] max-h-[12rem]" /> */}
      <div className="flex justify-center items-center ">
        <img src={data["image"]} className="rounded-md" />
      </div>
      <div className="flex flex-row flex-wrap justify-between items-start py-1">
        <span className=" md:p-1 font-semibold text-xsm border rounded-md">
          {data["Language"]}
        </span>
        <span className=" md:p-1  font-semibold text-indigo-500 text-xs">
          {data["Subject"]}
        </span>
        <span className=" md:p-1 font-semibold text-sm md:text-base leading-tight">
          {data["CourseName"]}
        </span>
      </div>
      <span className=" md:p-1 font-light text-xs tablet:text-sm md:text-sm">
        {data["Lecturer"]}
      </span>
      <span className=" md:p-1 font-light text-xs tablet:text-sm md:text-sm">
        {data["StartDur"]}
      </span>
    </div>
  );
}

export default CourseCard;
