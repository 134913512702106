import React, { useState } from "react";

import loginImg from "../../../assets/Dexlabz/hero-bg.svg";
import CustomButton from "../Shared/CustomButton/CustomButton";
import OtpInput from "react-otp-input";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import OTPInput from "react-otp-input";
import axios, { HttpStatusCode } from "axios";
import { ApiConstants } from "../../../api/ApiConstants";
import Registration from "../Registration/Registration";

function JoinFree({ setShowJoinFreePopup, setShowLoginPopup, className }) {
	const [isOtpRequested, setIsOtpRequested] = useState(false);
	const [otp, setOtp] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [successMessage, setSuccessMessage] = useState();
	const [isSignUpForm, setIsSignUpForm] = useState(false);

	function ValidateEmail(mail) {
		if (
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
				mail
			)
		) {
			return true;
		}
		return false;
	}
	// console.log(email);

	const handleGenerateOtp = async () => {
		if (email === undefined || email === null || email.length === 0) {
			setError(true);
			setErrorMessage("Email is required");
			return;
		}

		if (!ValidateEmail(email)) {
			setErrorMessage("Email entered is incorrect");
			setError(true);
			return;
		}

		var bodyObject = {
			email: email,
		};

		try {
			const { status } = await axios.post(
				ApiConstants.BASE_URL + "" + ApiConstants.REGISTRATION_GENERATE_OTP_URL,
				bodyObject
			);

			if (status === HttpStatusCode.Ok) {
				setIsOtpRequested(true);
				setSuccess(true);
				setSuccessMessage(`We have sent an OTP on ${email}`);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleVerifyOtp = async (e) => {
		if (otp.length < 6) {
			return;
		}

		var bodyObject = {
			email: email,
			otp: otp,
		};

		try {
			const { status } = await axios.post(
				ApiConstants.BASE_URL + "" + ApiConstants.REGISTRATION_VERIFY_OTP_URL,
				bodyObject
			);

			if (status === HttpStatusCode.Ok) {
				setIsSignUpForm(true);
				console.log("otp verify ho gya hai");
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
    <div>
      <div className="bg-[rgba(128,128,128,.8)] w-full h-screen fixed top-0 left-0 z-50 rounded-md">
        <div
          className={`bg-white h-[40rem] md:h-[35rem] w-[85%]  lg:w-[70%] xl:w-[60%] 2xl:w-[50%] tablet:w-[50%] 2sm:w-[60%] absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto  rounded-lg flex items-start justify-between flex-col md:flex-row px-0 ${className}`}
        >
          <div className="md:w-[50%] bg-blue-100  p-5 rounded-s-md h-[18rem] md:h-[35rem] flex flex-col hidden md:block tablet:hidden ">
            <div className="text-3xl ">
              <AiFillCloseCircle
                className="cursor-pointer"
                onClick={() => setShowJoinFreePopup(false)}
              />
            </div>

            <img src={loginImg} className="scale-90" alt="" />

            <div className=" px-5 text-gray-500 ">
              <p className="font-bold text-gray-600">
                Over 10 crore learners trust us for their preparation
              </p>
              <ul className="list-disc ps-5 mt-5">
                <li className="my-5">
                  Learn from 2,000+ India’s top educators
                </li>
                <li className="my-5">
                  Access 3,00,000+ live classes, test series, courses and more
                  for free
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full md:w-[50%]  tablet:w-full p-4 md:p-10 flex justify-start flex-col items-start  h-full relative rounded-lg tablet:rounded-lg md:rounded-none">
            <div className="text-3xl ms-auto md:hidden tablet:block">
              <AiFillCloseCircle
                className="cursor-pointer"
                onClick={() => setShowJoinFreePopup(false)}
              />
            </div>

            <p className=" text-2xl tablet:text-3xl  text-center lg:text-3xl font-bold">
              Join UpMyPrep
            </p>
            <p className=" text-sm tablet:text-sm tablet:ms-0 tablet:ps-0  text-center 2xl:text-sm mt-2 ">
              Already have an account?{" "}
              <Link
                to=""
                className="text-red-500  font-bold"
                onClick={() => {
                  setShowJoinFreePopup(false);
                  setShowLoginPopup(true);
                }}
              >
                Log in
              </Link>
            </p>

            <div className="my-5  w-full  ">
              {error && (
                <p className="bg-red-200 text-red-800 p-2 my-3 rounded">
                  {errorMessage}
                </p>
              )}

              {!isOtpRequested && (
                <div className="">
                  <div className="sm:col-span-3">
                    <div className="my-2">
                      <input
                        type="email"
                        value={email}
                        className="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6"
                        placeholder="Email address"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError(false);
                        }}
                      />
                    </div>
                  </div>
                  <p className="my-2 text-sm text-gray-600 ">
                    We’ll send an OTP for verification
                  </p>
                </div>
              )}

              {!isSignUpForm && (
                <div className="">
                  {success && (
                    <p className="bg-green-100 text-green-800 p-2 text-sm my-3 rounded">
                      {successMessage}
                    </p>
                  )}

                  {isOtpRequested && (
                    <div className="flex justify-center my-5 ">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        inputStyle={{ width: "2.5rem" }}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className="border border-black rounded-md px-2 mx-1  h-[3rem] "
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {isSignUpForm && (
                <Registration
                  setEmail={setEmail}
                  email={email}
                  setShowLoginPopup={setShowLoginPopup}
                  setShowJoinFreePopup={setShowJoinFreePopup}
                  setIsOtpRequested={setIsOtpRequested}
                />
              )}

              {!isOtpRequested && (
                <CustomButton
                  lable={"Request OTP"}
                  className={`w-full !mx-0 my-4 !text-sm bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
                  onClick={() => handleGenerateOtp()}
                />
              )}

              {isOtpRequested && !isSignUpForm && (
                <CustomButton
                  lable={"Verify OTP"}
                  className={`w-full !mx-0 my-4 !text-sm bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
                  onClick={() => handleVerifyOtp()}
                />
              )}

              {isOtpRequested && !isSignUpForm && (
                <p className=" text-xs   text-center 2xl:text-sm mt-2 font-bold  underline decoration-dashed underline-offset-8 tracking-widest">
                  <Link to="" className="text-bold ">
                    Continue with email
                  </Link>
                </p>
              )}

              <p className=" text-xsm   text-center 2xl:text-sm mt-2  absolute bottom-5 w-full tablet:w-full md:w-[80%]   ">
                Having trouble? Write us on{" "}
                <Link to="" className="text-bold ">
                  help@upmyprep.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinFree;
