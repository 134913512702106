import React from "react";

function OfflinePopup() {
	return (
		<div className="">
			<div className="flex justify-center items-center h-[70vh] p-5 md:p-0">
				<div className="flex flex-col items-center justify-center">
					<img
						src={require("../../../assets/Dexlabz/check-internet.png")}
						alt=""
						className="w-[50%]"
					/>
					<p className="text-xl  text-gray-800 -translate-y-10">
						Please Check your Internet
					</p>
				</div>
			</div>
		</div>
	);
}

export default OfflinePopup;
