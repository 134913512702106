import React from "react";
import { BsPeopleFill, BsCollectionPlayFill } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import {
  BiSolidBookBookmark,
  BiSolidBook,
  BiSolidMessageRoundedDetail,
} from "react-icons/bi";
import {
  MdFeaturedPlayList,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

function FeaturesContainer() {
  return (
    <div className="">
      <div className="flex flex-row flex-wrap w-[95%] tablet:w-[90%] lg:w-[70%]   justify-center mx-auto ">
        <FeatureChildComp
          label={"Live classes"}
          icon={<BsCollectionPlayFill />}
          description={"Watch free online coaching by out best educators."}
          color={"text-[#F77475]"}
          bcolor={"hover:border-[#F77475]"}
        />
        <FeatureChildComp
          label={"Top Educators"}
          icon={<BsPeopleFill />}
          description={"Learn from some of the best educators in the country."}
          color={"text-[#418DF7]"}
          bcolor={"hover:border-[#418DF7]"}
        />
        <FeatureChildComp
          label={"Batches"}
          icon={<FaPeopleGroup />}
          description={
            "Curated batches to simplify the learning journey for your goal."
          }
          color={"text-[#F0B234]"}
          bcolor={"hover:border-[#F0B234]"}
        />
        <FeatureChildComp
          label={"Courses"}
          icon={<BsCollectionPlayFill />}
          description={
            "Learn every subject in detail from your favourite educator."
          }
          color={"text-[#5973FE]"}
          bcolor={"hover:border-[#5973FE]"}
        />
        <FeatureChildComp
          label={"Playlist"}
          icon={<MdFeaturedPlayList />}
          description={
            "High quality lecture videos for the entire syllabus for all your subjects."
          }
          color={"text-[#8DCCD1]"}
          bcolor={"hover:border-[#8DCCD1]"}
        />
        <FeatureChildComp
          label={"Practice"}
          icon={<BiSolidBookBookmark />}
          description={
            "Strengthen your exam preparation with adaptive practice tests."
          }
          color={"text-[#9975FE]"}
          bcolor={"hover:border-[#9975FE]"}
        />
        <FeatureChildComp
          label={"Test series"}
          icon={<BiSolidBook />}
          description={
            "Evaluate and boost your exam preparation with test series."
          }
          color={"text-[#46BAE6]"}
          bcolor={"hover:border-[#46BAE6]"}
        />
        <FeatureChildComp
          label={"Doubts and solutions"}
          icon={<BiSolidMessageRoundedDetail />}
          description={
            "Get quick and detailed solutions to clarify your doubts."
          }
          color={"text-[#08BD80]"}
          bcolor={"hover:border-[#08BD80]"}
        />

        {/* bcolor={"hover:border-[#08BD80]"} */}
      </div>
    </div>
  );
}

const FeatureChildComp = ({ label, description, icon, color, bcolor }) => {
  return (
    <div
      className={` border border-1  rounded-lg  cursor-pointer m-2
       ${bcolor}  w-[80%] 2sm:w-[30%] tablet:w-[30%] lg:w-[23%] flex justify-center 
       items-center px-2 py-2 `}
    >
      <div className="flex flex-col">
        <div className="mt-3 flex flex-row justify-between items-center space-x-5">
          <div className="flex ml-1 mr-1 items-center ">
            <p className={`${color} mt-[1px] text-2xl`}>{icon}</p>
            <p className="text-base  font-bold mx-2 ">{label}</p>
          </div>
          <MdOutlineKeyboardArrowRight className="text-xl" />
        </div>
        <p className="text-xs mb-3 mr-2 ml-7 mt-1">{description}</p>
      </div>
    </div>
  );
};

export default FeaturesContainer;
