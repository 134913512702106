// import jwtDecode from "jwt-decode";

export const Utilits = {
  isCouponExpired: (expiryDate) =>
    Math.floor(Date.now() / 1000) > Date.parse(expiryDate) / 1000,

  formatDate: (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  formatTime: (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours}h : ${minutes}m : ${seconds}s`;
  },

  formatHtml: (data) => {
    const htmlString = data;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const text = doc.body.textContent;
    return text;
  },

  formateName: (fullName) => {
    const firstName = fullName.split(" ")?.[0].split("")?.[0];
    const lastName = fullName
      .split(" ")
      ?.[fullName.split(" ").length - 1].split("")?.[0];
    return `${firstName.toUpperCase()}${lastName.toUpperCase()}`;
  },
};
