import React from "react";

function Faq() {
	return (
		<div className="flex justify-center flex-col  items-center   ">
			<div className="w-[95%]  lg:w-[70%] tablet:w-[90%]  flex justify-between   tablet:py-3 items-start my-10 lg:px-10 ">
				<p className="">Frequently asked questions</p>
			</div>
		</div>
	);
}

export default Faq;
