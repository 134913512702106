import { Carousel } from "react-responsive-carousel";
import { TiTick } from "react-icons/ti";
import center_1 from "../../../assets/Dexlabz/center-1.avif";
import center_2 from "../../../assets/Dexlabz/center-2.avif";
import center_3 from "../../../assets/Dexlabz/center-3.avif";
import center_4 from "../../../assets/Dexlabz/center-4.jpg";
import center_5 from "../../../assets/Dexlabz/center-5.avif";
import center_6 from "../../../assets/Dexlabz/center-6.avif";
import center_7 from "../../../assets/Dexlabz/center-7.jpg";
const CentersCase = ({ id }) => {
  const CentresImages = [
    { i: center_1 },
    { i: center_2 },
    { i: center_3 },
    { i: center_4 },
    { i: center_5 },
    { i: center_6 },
    { i: center_7 },
  ];
  const Cities = [
    {
      name: "Ludhiana",
      state: "Punjab",
      image:
        "https://static.uacdn.net/ocx/centreicons/ludhiana.png?q=75&auto=format%2Ccompress&w=48",
    },
    {
      name: "Jammu",
      state: "Jammu & Kashmir",
      image:
        "https://static.uacdn.net/ocx/centreicons/Jammu-active.png?q=75&auto=format%2Ccompress&w=48",
    },
    {
      name: "Muzaffarpur",
      state: "Bihar",
      image:
        "https://static.uacdn.net/ocx/centreicons/Muzaffarpur-active.png?q=75&auto=format%2Ccompress&w=48",
    },
    {
      name: "Ranchi",
      state: "Jharkhand",
      image:
        "https://static.uacdn.net/ocx/centreicons/Ranchi-active.png?q=75&auto=format%2Ccompress&w=48",
    },
    {
      name: "Bhubaneswar",
      state: "Odisha",
      image:
        "https://static.uacdn.net/ocx/centreicons/Bhubaneswar-active.png?q=75&auto=format%2Ccompress&w=48",
    },
    {
      name: "View all",
      state: "",
      image:
        "https://static.uacdn.net/production/_next/static/images/offlineCentres/viewAll.svg?q=75&auto=format%2Ccompress&w=48",
    },
  ];
  return (
    <div
      className="flex flex-col justify-center items-center w-full mt-5"
      id={id}
    >
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] px-5">
        <p className="leading-tight font-semibold text-[#313639] text-lg md:text-2xl">
          Explore UpMyPrep Centres
        </p>
        <p className="leading-relaxed text-sm w-[100%] my-1">
          Learn from top educators in your own city
        </p>
        <div className="flex flex-col md:flex-row text-[15px] justify-start items-start mb-2">
          <span className="flex items-center p-1 ">
            <TiTick size={30} /> In-person classes and doubt solving
          </span>
          <span className="flex items-center p-1">
            <TiTick size={30} /> Exclusive quiet spaces for your self-study
          </span>
          <span className="flex items-center p-1">
            <TiTick size={30} /> Bonus access to online learning
          </span>
        </div>
      </div>
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%]  px-5">
        <Carousel
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={false}
          autoPlay={true}
          showArrows={false}
        >
          {CentresImages.map((image, index) => (
            <div className="h-[150px] md:h-[450px]" key={index}>
              <img src={image.i} className="h-[100%] w-[100%]" />
            </div>
          ))}
        </Carousel>
        {/* <p className="self-start leading-tight font-bold text-base md:text-lg my-2 mt-3">
          Our Centres are now open across india
        </p> */}
      </div>

      {/* <div className="flex flex-wrap justify-between w-[95%] tablet:w-[90%] lg:w-[70%]  px-5">
        {Cities.map((city, index) => (
          <div
            className="flex border-2 rounded-md w-[48%] tablet:w-[30%] md:w-[15%] justify-start items-center shadow-sm p-1 hover:bg-gray-100 mb-1 mr-1"
            key={index}
          >
            <img src={city.image} className="w-[27%] mr-1" />
            <div className="flex flex-col">
              <p className="text-xsm md:text-sm leading-tight font-semibold">
                {city.name}
              </p>
              <span className="leading-relaxed text-[9px]">{city.state}</span>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default CentersCase;
