import React from "react";

function LearnStore() {
	const learnCard = [
		{
			discripstion:
				"really really great institution.... atleast for gate .. educators are so perfect they make the platform complete paired   with excellent ui and presentation",
			teacher: "Ankan Brahmachary",
			subject: "GATE & ESE - Civil",
		},
		{
			discripstion:
				"really really great institution.... atleast for gate .. educators are so perfect they make the platform complete paired   with excellent ui and presentation",
			teacher: "Ankan Brahmachary",
			subject: "GATE & ESE - Civil",
		},
		{
			discripstion:
				"really really great institution.... atleast for gate .. educators are so perfect they make the platform complete paired   with excellent ui and presentation",
			teacher: "Ankan Brahmachary",
			subject: "GATE & ESE - Civil",
		},
		{
			discripstion:
				"really really great institution.... atleast for gate .. educators are so perfect they make the platform complete paired   with excellent ui and presentation",
			teacher: "Ankan Brahmachary",
			subject: "GATE & ESE - Civil",
		},
	];

	return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-start lg:p-5  ">
      <div className="w-[90%] md:w-[30%] p-5">
        <p className="text-xl font-bold">6,50,000+ learners trust UpMyPrep</p>
      </div>
      <div className="w-full  md:w-[60%] flex flex-col lg:flex-row flex-wrap justify-between items-center lg:items-start">
        {/* ......................................... */}

        {learnCard?.map((data, index) => (
          <div
            className="flex bg-white w-[90%] lg:w-[45%] p-3 rounded-md shadow-lg my-3 relative"
            key={index}
          >
            <div className="w-[90%]">
              <p className="text-sm  text-gray-500 line-clamp-3 ">
                {data?.discripstion}
              </p>
              <p className="text-sm font-semibold text-gray-700 mt-5">
                {data?.teacher}
              </p>
              <p className="text-xs font-semibold text-gray-500">
                {data?.subject}
              </p>
            </div>
            <div className=" absolute right-2 top-1">
              <img
                src="https://static.uacdn.net/production/_next/static/images/open-quote.svg?q=75&auto=format%2Ccompress&w=32"
                alt=""
                className="rotate-180 "
              />
            </div>
          </div>
        ))}

        {/* ........................................................... */}

        {/* ........................................................... */}
      </div>
    </div>
  );
}

export default LearnStore;
