import Home from "./ UnacademyPages/UnacademyHome/Home";

const UnacademyTheme = () => {
	return (
    // <div className="flex justify-center items-center w-full h-screen bg-slate-800">
    //   <p className="text-white text-2xl">UpMyPrep Home</p>
    // </div>

    <div>
      <Home />
    </div>
  );
};

export default UnacademyTheme;
