import {
  applyMiddleware,
  configureStore,
  compose,
} from "@reduxjs/toolkit";
import appReducer from "./AppStateReducer";
import thunk from "redux-thunk";
import courseReducer from "./courseSlice";
import goalsReducer from "./GoalsSlice";
import loginReducer from "./LoginSlice";
import JoinFreeReducer from "./JoinFreeSlice";
import userSelectGoalReducer from "./UserSelectGoalSlice";
import { DashBoardApi } from "../api/ReduxToolkit";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = configureStore(
  {
    reducer: {
      appState: appReducer,
      courseReducer: courseReducer,
      goalsReducer: goalsReducer,
      loginReducer: loginReducer,
      JoinFreeReducer: JoinFreeReducer,
      userSelectGoalReducer: userSelectGoalReducer,
      [DashBoardApi.reducerPath]: DashBoardApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(DashBoardApi.middleware),
  },
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
