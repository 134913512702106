import { useNavigate } from "react-router-dom";
import CustomButton from "../Shared/CustomButton/CustomButton";

const ReferAfriend = () => {
  const navigate =  useNavigate()
  return (
		<div className="flex items-center justify-center  w-full mt-10">
			<div className="flex flex-col-reverse  md:flex-row justify-between items-center rounded-md tablet:w-[90%] lg:w-[70%] p-5 z-20 border ">
				<div className="flex flex-col p-3 w-[95%] md:w-[50%] ">
					<p className="leading-tight font-semibold text-[#313639] text-lg md:text-2xl p-3">
						Refer friends to win Amazon vouchers and Plus Subscription
					</p>
					<p className="leading-relaxed p-2 text-sm tablet:text-sm md:text-base">
						For every successful referral win up to ₹2,500. Also, get 1 month
						plus subscription once
					</p>

					<CustomButton
						className={
							"bg-gray-900 text-white hover:bg-gray-800 text-base tablet:text-sm md:text-lg m-2 lg:w-[40%]"
						}
						lable={"Refer a friend"}
						onClick={() => navigate("/refer")}
					/>
				</div>
				<div className=" md:w-[50%] flex justify-center">
					<img
						src="https://static.uacdn.net/referrals/refer_friends_and_win_rewards_web.png"
						className="w-[90%] md:w-[70%] "
						alt=""
					/>
				</div>{" "}
			</div>
		</div>
	);
};

export default ReferAfriend;
