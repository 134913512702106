import React from "react";

import { useNavigate } from "react-router-dom";

function EmptyStore() {
	return (
		<div className="">
			<div className="flex justify-center items-center h-[70vh] p-5 md:p-0 ">
				<div className="flex flex-col items-center justify-center">
					<img
						src={require("../../../assets/Dexlabz/enpty-stoe.png")}
						alt=""
						className="w-[50%]"
					/>
					<p className="text-2xl font-bold text-gray-800">
						Oops! There is no data on this goal
					</p>
				</div>
			</div>
		</div>
	);
}

export default EmptyStore;
