import CustomButton from "../Shared/CustomButton/CustomButton";
import CourseCard from "./CourseCard";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CoursesShow = () => {
  const CoursesData = [
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-1",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-2",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-3",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
    {
      CourseName: "Binomial Theorem-4",
      image:
        "https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
      Lecturer: "Ramesh Nethetla",
      Subject: "MATHEMATICS",
      Language: "English",
    },
  ];
  return (
    <div className="flex flex-col justify-center items-center w-[95%] tablet:w-[90%] lg:w-[70%]  mx-3">
      <div className="flex justify-between items-center w-full  tablet:w-[100%]">
        <p className="text-sm md:text-lg font-semibold text-[#313639]  ">
          Recommended batch for you
        </p>
        <CustomButton
          className={
            "p-2 text-sm md:text-base rounded mb-2 hover:bg-gray-200 border-none bg-gray-100"
          }
          lable={"see all"}
        />
      </div>

      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className="w-full self-center z-0 "
        containerClass="container-with-dots "
        dotListClass={`text-5xl`}
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 5.2,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1.1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 3.2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {CoursesData.map((courseData, index) => (
          <CourseCard data={courseData} key={index} />
        ))}
      </Carousel>

      {/* <div className="flex flex-row overflow-x-scroll no-scrollbar w-full ">
          {CoursesData.map((courseData, index) => (
            <CourseCard data={courseData} key={index} />
          ))}
        </div> */}
    </div>
  );
};

export default CoursesShow;
