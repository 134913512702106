import TopEducator from "./TopEducator";
import { BsCheckCircle } from "react-icons/bs";
import CustomButton from '../Shared/CustomButton/CustomButton';
import { useEffect } from "react";

const TopEducatorSection = ({ id }) => {
    
    

    const TeacherData = [
        {
            image: "https://static.uacdn.net/educator_new/processed_image/4386E14E-11AF-4826-A8F7-EA1A5CFD9A94_.png?q=75&auto=format%2Ccompress&w=128",
            name: "Vineet Loomba",
            description: "A game-changer in Mathematics, produced 100 percentilers in 2021/2022/2023, his course is regarded as the....",
            watchtime: "176M",
            followers: "176M"
        },
        {
            image: "https://static.uacdn.net/educator_new/processed_image/4386E14E-11AF-4826-A8F7-EA1A5CFD9A94_.png?q=75&auto=format%2Ccompress&w=128",
            name: "Vineet Loomba",
            description: "A game-changer in Mathematics, produced 100 percentilers in 2021/2022/2023, his course is regarded as the....",
            watchtime: "176M",
            followers: "176M"
        },
        {
            image: "https://static.uacdn.net/educator_new/processed_image/4386E14E-11AF-4826-A8F7-EA1A5CFD9A94_.png?q=75&auto=format%2Ccompress&w=128",
            name: "Vineet Loomba",
            description: "A game-changer in Mathematics, produced 100 percentilers in 2021/2022/2023, his course is regarded as the....",
            watchtime: "176M",
            followers: "176M"
        },
        {
            image: "https://static.uacdn.net/educator_new/processed_image/4386E14E-11AF-4826-A8F7-EA1A5CFD9A94_.png?q=75&auto=format%2Ccompress&w=128",
            name: "Vineet Loomba",
            description: "A game-changer in Mathematics, produced 100 percentilers in 2021/2022/2023, his course is regarded as the....",
            watchtime: "176M",
            followers: "176M"
        }
    ]
    return (
      <div className="flex justify-center items-center rounded-md mt-10" id={id}>
        <div className="w-[95%] lg:w-[70%] tablet:w-[88%] flex flex-wrap border-b pb-4 ">
          <div className="flex justify-center items-center py-5">
            <div className="flex flex-col">
              <span className="text-lg font-semibold lg:text-2xl leading-relaxed  pb-3 text-[#313639]">
                India's top educators to learn from
              </span>
              <div className="flex flex-col md:flex-row text-sm justify-start items-start">
                <span className="flex items-center p-1 text-xs md:text-sm  me-5">
                  <BsCheckCircle className="me-3 " /> Proven history of delivering results
                </span>
                <span className="flex items-center p-1 text-xs md:text-sm me-5">
                  <BsCheckCircle className="me-3 " /> Mentored past rankers
                </span>
                <span className="flex items-center p-1 text-xs md:text-sm me-5">
                  <BsCheckCircle className="me-3 " /> Curated by best educators
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col 2sm:flex-row md:flex-row flex-wrap justify-between  w-full items-center ">
            {TeacherData.map((teacher, index) => (
              <TopEducator data={teacher} key={index} />
            ))}
          </div>
          <CustomButton
            className={"bg-gray-900 text-white hover:bg-gray-800   mt-5"}
            lable={"View all educators"}
          />
        </div>
      </div>
    );
}

export default TopEducatorSection