import React, { useEffect, useState } from "react";



import CustomButton from "../Shared/CustomButton/CustomButton";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import { Link, useNavigate } from "react-router-dom";

// import 'react-phone-input-2/lib/style.css'




import "react-phone-input-2/lib/bootstrap.css";

import axios, { HttpStatusCode } from "axios";

import { ApiConstants } from "../../../api/ApiConstants.jsx";
import { NetworkCall, RequestType } from "../../../network/axios-config";
import Login from "../Login/Login";
import JoinFree from "../JoinFree/JoinFree";

const Header = () => {
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showJoinFreePopup, setShowJoinFreePopup] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);

  const [otp, setOtp] = useState("");

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const [isOtpRequested, setIsOtpRequested] = useState(false);
  const [isSignUpForm, setIsSignUpForm] = useState(false);

  const [showPasswordField, setShowPasswordField] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (showLoginPopup || showJoinFreePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showLoginPopup, showJoinFreePopup]);

  useEffect(() => {

    // console.log(otp);
    setSuccess(false);

    if (otp.length === 6) {
      handleVerifyOtp();
    }
  }, [otp]);

  function ValidateEmail(mail) {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  } 

  const handleGenerateOtp = async () => {
    if (email === undefined || email === null || email.length === 0) {
      setError(true);
      setErrorMessage("Email is required");
      return;
    }

    if (!ValidateEmail(email)) {
      setErrorMessage("Email entered is incorrect");
      setError(true);
      return;
    }

    var bodyObject = {
      email: email,
    };

    try {
      const { status } = await axios.post(
        ApiConstants.BASE_URL + "" + ApiConstants.REGISTRATION_GENERATE_OTP_URL,
        bodyObject
      );

      if (status === HttpStatusCode.Ok) {
        setIsOtpRequested(true);
        setSuccess(true);
        setSuccessMessage(`We have sent an OTP on ${email}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOtp = async (e) => {
    if (otp.length < 6) {
      return;
    }

    var bodyObject = {
      email: email,
      otp: otp,
    };

    try {
      const { status } = await axios.post(
        ApiConstants.BASE_URL + "" + ApiConstants.REGISTRATION_VERIFY_OTP_URL,
        bodyObject
      );

      if (status === HttpStatusCode.Ok) {
        setIsSignUpForm(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async () => {
    if (email === undefined || email === null || email.length === 0) {
      setError(true);
      setErrorMessage("Email is required");
      return;
    }

    if (!ValidateEmail(email)) {
      setErrorMessage("Email entered is incorrect");
      setError(true);
      return;
    }

    if (password === undefined || password === null || password.length === 0) {
      setError(true);
      setErrorMessage("Password is required");
      return;
    }

    var bodyObject = {
      email,
      password,
    };

    try {
      // NetworkCall(RequestType.POST, ApiConstants.LOGIN_END_POINT,bodyObject);
      const { status, data } = await NetworkCall(
        RequestType.POST,
        ApiConstants.LOGIN_END_POINT,
        bodyObject
      );
      if (status === HttpStatusCode.Ok) {
        localStorage.setItem("token", data?.serverResponse?.accessToken);
        document.body.style.overflow = "scroll";
        navigate("/dashboard");
        email("")
        password("")
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className=" flex justify-center ">
        <div className="w-full mx-2 lg:w-[70%]  flex justify-between  ">
          <div className="">
            <BrandLogo />
          </div>

          <div className="flex items-center justify-end w-full">
            <CustomButton
              onClick={() => setShowLoginPopup(true)}
              lable={"Log in"}
              className={`border-gray-200 hover:bg-gray-200`}
            />
            <CustomButton
              onClick={() => setShowJoinFreePopup(true)}
              lable={"Join for free"}
              className={`bg-gray-700 border-gray-700 hover:border-gray-800 text-white hover:bg-gray-800`}
            />
          </div>

          {showLoginPopup && (
            <Login
              className="animate-wiggle"
              setShowLoginPopup={setShowLoginPopup}
              setShowJoinFreePopup={setShowJoinFreePopup}
            />
          )}

          {showJoinFreePopup && (
            <JoinFree
              className="animate-wiggle"
              setShowJoinFreePopup={setShowJoinFreePopup}
              setShowLoginPopup={setShowLoginPopup}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
