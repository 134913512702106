import React from 'react'


import Header from '../UnacademyHeader/Header'
import UnacademyHeroContainer from '../UnacademyHeroContainer/UnacademyHeroContainer'
import GoalContainer from '../GoalContainer/GoalContainer'
import IntroContainer from '../IntroContainer/IntroContainer'
import ServicesContainer from '../ServicesContainer/ServicesContainer'
import SubscribeAdvertisment from '../UnacademyComponents/SubscribeAdvertisment';
import GetLearningContainer from '../GetLearningContainer/GetLearningContainer'
import Registration from '../Registration/Registration'
import LightFooter from '../Footer/LightFooter'


function Home() {

  
  
  return (
    <div>

      <div><Header /></div>
      <div><UnacademyHeroContainer /></div>
      <div><GoalContainer /></div>
      <div><IntroContainer /></div>
      <div><ServicesContainer /></div>
      <div><GetLearningContainer/></div>
      <div><LightFooter /></div>
      
    </div>
  )
}

export default Home
