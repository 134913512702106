import React from "react";

import CustomButton from "../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

function Unauthorise() {
	const navigate = useNavigate();

	return (
		<div className="">
			<div className="flex justify-center items-center h-screen p-5 md:p-0">
				<div className="text-center">
					<img
						src={require("../../../assets/Dexlabz/unauthorized_page_image.png")}
						alt=""
					/>
					<p className="text-2xl font-bold text-gray-800">
						Oops! Page not found...
					</p>
					<p className="my-5 text-gray-400 text-sm">
						Looks like you're looking for something that doesn't exist.
					</p>
					<CustomButton
						lable={"Go Home"}
						className={`bg-green-500 text-white border-none rounded-md hover:bg-green-600 !text-lg md:!text-base`}
						onClick={() => navigate("/")}
					/>
				</div>
			</div>
		</div>
	);
}

export default Unauthorise;
