import React, { useEffect, useState } from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";

import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useGetStoreDataByActiveGoalQuery } from "../../../api/ReduxToolkit";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import DashBoardLoder from "../DashBoardLoder/DashBoardLoder";
import EmptyStore from "../SubStore/EmptyStore";
import { optimizeImage } from "../../../api/ApiConstants";
import SubStore from "../SubStore/SubStore";

function UnacademeyStore({ activeGoalId, loading }) {
  const navigate = useNavigate();
  const [showSubjectBox, setShowSubjectBox] = useState(false);
  const [showLanguageBox, setShowLanguageBox] = useState(false);
  const [showLiveBox, setShowLiveBox] = useState(false);
  const [showProductBox, setShowProductBox] = useState(false);
  const [currentCradData, setCurrentCardData] = useState([]);
  const [showSubStore, setShowSubStore] = useState(false);

  const { data, isLoading } = useGetStoreDataByActiveGoalQuery(
    activeGoalId?._id || ""
  );

  const handleCardClick = (data, index) => {
    setCurrentCardData(data);
    setShowSubStore(true);

    // navigate("/sub-store");
  };

  return (
    <div className="">
      {!showSubStore && (
        <div>
          <Toolbar />

          {loading === true ? (
            <DashBoardLoder />
          ) : (
            <div className="flex justify-center my-10">
              <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex flex-col lg:flex-row ">
                <div className=" w-full lg:w-[20%] tablet:w-[90%] lg:ps-5 ">
                  <p className="text-xl font-bold border-b my-1 py-3 cursor-pointer text-gray-800">
                    Filters
                  </p>

                  <div className="font-semibold text-sm tablet:text-xs flex flex-row lg:flex-col flex-wrap w-full  justify-between">
                    <div className="lg:border-b my-1 py-3 cursor-pointer">
                      <p
                        className="flex items-center justify-between text-gray-600"
                        onClick={() => setShowSubjectBox(!showSubjectBox)}
                      >
                        SUBJECT{" "}
                        <MdKeyboardArrowDown
                          className={`text-2xl me-3 duration-300 ${
                            showSubjectBox ? "rotate-180" : ""
                          }`}
                        />
                      </p>
                      {showSubjectBox && (
                        <ul className="my-2">
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            Civil Engineering
                          </li>
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            GA, GS and Methematics
                          </li>
                        </ul>
                      )}
                    </div>
                    <div
                      className="lg:border-b my-1 py-3 cursor-pointer"
                      onClick={() => setShowLanguageBox(!showLanguageBox)}
                    >
                      <p className="flex items-center justify-between text-gray-600">
                        LANGUAGE{" "}
                        <MdKeyboardArrowDown
                          className={`text-2xl me-3 duration-300 ${
                            showLanguageBox ? "rotate-180" : ""
                          }`}
                        />
                      </p>
                      {showLanguageBox && (
                        <ul className="my-2">
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            Hindi
                          </li>
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            English
                          </li>
                        </ul>
                      )}
                    </div>
                    <div
                      className="lg:border-b my-1 py-3 cursor-pointer"
                      onClick={() => setShowLiveBox(!showLiveBox)}
                    >
                      <p className="flex items-center justify-between text-gray-600">
                        LIVE/RECORDED{" "}
                        <MdKeyboardArrowDown
                          className={`text-2xl me-3 duration-300 ${
                            showLiveBox ? "rotate-180" : ""
                          }`}
                        />
                      </p>
                      {showLiveBox && (
                        <ul className="my-2">
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            Civil Engineering
                          </li>
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            GA, GS and Methematics
                          </li>
                        </ul>
                      )}
                    </div>
                    <div
                      className="lg:border-b my-1 py-3 cursor-pointer"
                      onClick={() => setShowProductBox(!showProductBox)}
                    >
                      <p className="flex items-center justify-between text-gray-600">
                        PRODUCT TYPE{" "}
                        <MdKeyboardArrowDown
                          className={`text-2xl me-3 duration-300 ${
                            showProductBox ? "rotate-180" : ""
                          }`}
                        />
                      </p>
                      {showProductBox && (
                        <ul className="my-2">
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            Civil Engineering
                          </li>
                          <li className="py-1 flex items-center text-gray-500">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mx-2 cursor-pointer"
                            />
                            GA, GS and Methematics
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className="lg:w-[80%] tablet:w-[90%] lg:border-l ">
                  {data && data.courses && data.courses.length > 0 ? (
                    <div className="m-5  flex flex-row flex-wrap">
                      {/* card code start */}

                      {data?.courses?.map((data, index) => (
                        <div
                          className="bg-white w-[90%] lg:w-[300px] tablet:w-[30%] mx-auto my-4 rounded-md shadow"
                          key={index}
                          onClick={() => {
                            handleCardClick(data, index);
                          }}
                        >
                          <div className="">
                            <img
                              // src={data?.courseThumbnail?.[0]?.url}
                              src={optimizeImage(
                                data?.courseThumbnail?.[0]?.resourceId,
                                300,
                                170,
                                60
                              )}
                              alt=""
                              className="rounded-md  cursor-pointer"
                              // onClick={() => navigate("/sub-store")}
                            />
                          </div>
                          <div className="px-2 my-1 relative h-[13rem] tablet:h-[15rem] cursor-pointer">
                            <p className="text-xs font-semibold my-2">
                              <span className="border me-2  rounded">
                                {data.language?.[0]?.languageCode}
                              </span>
                              <span className="text-blue-500">
                                {data?.courseType?.[0]}
                              </span>
                            </p>
                            <p className="font-semibold  text-gray-800">
                              {data?.courseName}
                            </p>
                            <p className="text-xs text-gray-500 my-2 line-clamp-3">
                              {data?.courseDescription}
                            </p>
                            {data?.isDiscounted === true && (
                              <p className="flex items-center text-orange-500 font-semibold text-xsm my-2 ">
                                <AiOutlineCloseCircle className="me-3 " />
                                {data?.couponsEligible?.[0]?.couponDescription}
                              </p>
                            )}
                            <div className="font-serif flex flex-row absolute bottom-1 left-3 ">
                              {data?.coursePricing?.[0]?.isDiscounted ===
                                true && (
                                <b className="flex items-center me-3">
                                  <span className="me-1">₹</span>
                                  {Math.round(
                                    data?.coursePricing?.[0]?.priceTag -
                                      data?.coursePricing?.[0]?.priceTag *
                                        (data?.coursePricing?.[0]
                                          ?.discountRate /
                                          100)
                                  )}
                                </b>
                              )}

                              {data?.coursePricing?.[0]?.isDiscounted ===
                              true ? (
                                <s className="flex items-center text-xs text-gray-400">
                                  <span className="me-1">₹</span>
                                  {data?.coursePricing?.[0]?.priceTag}
                                </s>
                              ) : (
                                <p className=" ">
                                  <b>
                                    <span className="me-1">₹</span>
                                    {data?.coursePricing?.[0]?.priceTag}
                                  </b>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* card code end */}
                    </div>
                  ) : (
                    <EmptyStore />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {showSubStore && (
        <SubStore
          setShowSubStore={setShowSubStore}
          currentCradData={currentCradData}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    activeGoalId: state?.userSelectGoalReducer?.userGoalInfo?.activeGoal,
    loading: state?.userSelectGoalReducer?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnacademeyStore);
