import React from "react";
import { FaRegCirclePlay } from "react-icons/fa6";

function ResumeLearningCard({
	courseTitle,
	courseImageUrl,
	onClick,
	lectureTitle,
	lectureDescription,
}) {
	return (
		<div
			className="flex flex-col  min-w-[10rem] lg:min-w-[18rem] me-5  p-1 rounded-md border border-gray-50 m-1 hover:bg-gray-100 cursor-pointer min-h-[10rem]"
			onClick={onClick}
		>
			<div className="flex justify-center items-center ">
				<img src={courseImageUrl} className="rounded-md" />
			</div>
			<div className="flex  flex-col flex-wrap justify-between items-start py-1">
				<span className=" md:p-1  text-xs md:text-sm leading-tight line-clamp-2">
					{courseTitle}
				</span>
				<div className="flex items-center capitalize gap-2 line-clamp-1">
					<FaRegCirclePlay className="text-2xl text-blue-600" />
					<span className=" md:p-1  text-xs md:text-sm leading-tight line-clamp-1 w-full font-thin">
						{lectureTitle} 
					</span>
				</div>
			</div>
		</div>
	);
}

export default ResumeLearningCard;
