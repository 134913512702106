import React, { useContext, useEffect, useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from "use-file-picker/validators";
import { useFilePicker } from "use-file-picker";
import axios from "axios";
import { AuthContext } from "../../../providers/AuthProvider";
import { ApiConstants } from "../../../api/ApiConstants";

function UserEditPopup({ className, setPopup, getData }) {
  const { userInfo } = useContext(AuthContext);
  const [progress, setProgress] = useState(0);
  const { openFilePicker, filesContent, loading } = useFilePicker({
    accept: "image/*",
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(["jpg", "jpeg", "png", "webp"]),
      new FileSizeValidator({ maxFileSize: 2 * 1024 * 1024 }),
      new ImageDimensionsValidator({
        maxHeight: 900, // in pixels
        maxWidth: 900,
      }),
    ],
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // console.log("onFilesSelected", plainFiles, filesContent, errors);
    },
    onFilesRejected: ({ errors }) => {
      console.log("onFilesRejected", errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // console.log("onFilesSuccessfullySelected", plainFiles, filesContent);
      uploadImage(plainFiles);
    },
  });

  const handleUploadImage = () => {
    // console.log("select ho rha hai");
    openFilePicker();
  };

  const handleSelect = (event) => {
    uploadImage(event.target.files);
  };

  const uploadImage = async (file) => {
    try {
      //   console.log("Upload Image", file[0]);

      // Create FormData
      var form_data = new FormData();
      form_data.append("userId", userInfo?.uid);
      form_data.append("file", file[0]);

      console.log("Form Data File", form_data);

      // Send request with Axios
      const { data } = await axios.put(
        `${ApiConstants?.BASE_URL}/user/uploadImage`, // Ensure this is the correct endpoint
        form_data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      // Handle response
      getData(data?.previewLink);
      setPopup(false);
    } catch (error) {
      console.error("Upload Error:", error);
      // Handle the error appropriately
      if (error.response) {
        console.error("Error Response:", error.response);
      } else if (error.request) {
        console.error("Error Request:", error.request);
      } else {
        console.error("Error Message:", error.message);
      }
    }
  };

  return (
    <div className="bg-[rgba(128,128,128,.8)] w-full  fixed h-screen top-0 z-50 ">
      <div
        className={`absolute  right-0 left-0 mx-auto bottom-5 z-10 w-[70%] 2sm:w-[50%] tablet:w-[40%] lg:w-[35%] xl:w-[25%]   text-gray-600 text-sm ms-auto flex flex-col justify-end  rounded-xl shadow-xl px-5 py-5   duration-700 border-t-4 border-orange-500 ${className} bg-white`}
      >
        <div className="flex justify-center">
          <img
            src={require("../../../assets/Dexlabz/userEditpopup.png")}
            alt=""
            className="w-[40%] md:w-[20%]"
          />
        </div>
        <div className="flex flex-col items-center ">
          <p className="text-sm tablet:text-base md:text-2xl font-semibold text-center mt-5">
            You can update your profile image only once
          </p>
          <p className="my-5 text-xsm tablet:text-xs  md:text-sm text-center w-[80%]">
            Make sure you give your best shot, since you’ll not be able to
            change this later
          </p>
        </div>
        {progress > 0 && progress < 100 && (
          <div className="h-[50px] w-[50px] self-center mb-5">
            <CircularProgressbar
              value={progress}
              text={`${progress}%`}
              // classes={{ width: "50px", height: "50px", background: "red" }}
            />
          </div>
        )}
        <p className="flex justify-center items-center ">
          <CustomButton
            lable={"Cancel"}
            className={` text-gray-500 border-gray-200 hover:bg-gray-200 rounded-md md:!px-10 md:!py-3 `}
            onClick={() => setPopup(false)}
          />

          <CustomButton
            onClick={handleUploadImage}
            lable={"Continue"}
            className={` hover:bg-red-500 text-white border-none bg-red-400 rounded-md md:!px-10 md:!py-3`}
          />
        </p>
        ;
      </div>
    </div>
  );
}

export default UserEditPopup;
