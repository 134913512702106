import React from "react";
import Lottie from "lottie-react";

import pending from "../../../assets/Dexlabz/payment-pending.json";

function PaymentPending() {
	return (
		<div className="flex flex-col justify-center items-center h-screen ">
			<Lottie
				animationData={pending}
				loop={false}
				width={"50px"}
				height={"50px"}
				className="w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
			/>
			<p className="-translate-y-3 font-semibold text-yellow-500">
				{" "}
				Payment Pending
			</p>
		</div>
	);
}

export default PaymentPending;
