import React from "react";
import Lottie from "lottie-react";

import lottieAnimation from "../../../assets/Dexlabz/loding.json";

function LoadingPage() {
	return (
		<div className="flex justify-center items-center h-screen ">
			<Lottie
				animationData={lottieAnimation}
				loop={true}
				width={"50px"}
				height={"50px"}
				className="w-[100px] h-[100px] md:w-[200px] md:h-[200px]"
			/>
		</div>
	);
}

export default LoadingPage;
