export const ApiConstants = {
  cloudFront: process.env.REACT_APP_CLOUD_FRONT_URL,

  BASE_URL: process.env.REACT_APP_BASE_URL,
  GET_DEFAULT_THEME: "/dashboard/getDefault",

  REGISTRATION_GENERATE_OTP_URL: "/user/generateOtp",
  REGISTRATION_VERIFY_OTP_URL: "/user/verifyOtp",
  UPDATE_USER_REGISTRATION_END_POINT: "/user/updateUser",
  LOGIN_END_POINT: "/user/login",
  ALL_GOAL_END_POINT: "/goals/get-allgoals",
  PROFILE_UPLOAD: "/user/uploadImage",
  USER_SELECT_GOAL: "/user/getUserInfo",
  ACTIVE_GOAL_STORE: "/course/getStoreData",
  UPDATE_ACTIVE_GOAL: "/user/updateActiveGoal",
  CREATE_ORDER: "/sales/statistics/createOrder",
  GET_ALL_SECTIONS_BY_COURSE_UID: "/section/course",
  GET_ALL_CHAPTER_BY_SECTION_UID: "/chapter/getChapterBySectionId",
  GET_ALL_LECTURE_BY_CHAPTER_UID: "/chapter/getLectureByChapterId",

  GET_LECTURE_BY_COURSE_UID: "/resource/getAllResourcesByCourseId",
  GET_LECTURE_BY_Lecture_UID: "/resource/getAllResourcesByLectureId",

  VALIDATE_COUPON: "/coupon/validateCoupon",
  PAYMENT_RESPONCE: "/sales/statistics/validateRequest",

  GET_ENROLLED_BY_USERID: "/enrollment/getEnrollmentByUserId",

  SAVE_VIDEO_PROGRESS: "/userbehaviour/saveLastWatch",
  VIDEO_WATCH_DURATION: "userbehaviour/resumeLearning",
  GET_ALL_QUESTION_BY_LECTURE_ID: "/question/getQuestionsByLectureId",
};

export const optimizeImage = (
  originalUrl,
  width = 150,
  height = 100,
  quality = 60
) => {
  const baseUrl = process.env.REACT_APP_IMAGE_OPTIMIZATION_URL;
  let optimizedUrl = baseUrl + ApiConstants.cloudFront + originalUrl;

  if (width) {
    optimizedUrl += `&width=${width}`;
  }
  if (height) {
    optimizedUrl += `&height=${height}`;
  }
  if (quality) {
    optimizedUrl += `&quality=${quality}`;
  }

  // console.log("url",optimizedUrl)

  return optimizedUrl;
};
