import React from "react";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";

import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiLogoYoutube,
  BiSolidPhoneCall,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function DarkFooter() {
  const navigate = useNavigate();

  const company = [
    {
      link: "",
      item: "About US",
    },
    {
      link: "",
      item: "Careers",
    },
    {
      link: "",
      item: "Blog",
    },
    {
      link: "/privacy-policy",
      item: "Privacy Policy",
    },
    {
      link: "/terms-conditions",
      item: "Terms and Conditions",
    },
  ];

  const HelpSupport = [
    {
      link: "",
      item: "User Guidelines",
    },
    {
      link: "",
      item: "Site Map",
    },
    {
      link: "/refund-policy",
      item: "Refund Policy",
    },
    {
      link: "",
      item: "Takedown Policy",
    },
    {
      link: "",
      item: "Grievance Redressal",
    },
  ];

  const products = [
    {
      link: "",
      item: "Learner app",
    },
    {
      link: "",
      item: "Educator app",
    },
    {
      link: "",
      item: "Parent app",
    },
    {
      link: "",
      item: "Educator app",
    },
    {
      link: "",
      item: "Parent app",
    },
  ];

  const goals = [
    {
      link: "",
      item: "IIT JEE",
    },
    {
      link: "",
      item: "UPSE",
    },
    {
      link: "",
      item: "SSC",
    },
    {
      link: "",
      item: "CSIR UGC NET",
    },
    {
      link: "",
      item: "NEET UG ",
    },
  ];

  const exams = [
    {
      link: "",
      item: "GATE",
    },
    {
      link: "",
      item: "CAT",
    },
    {
      link: "",
      item: "CA",
    },
    {
      link: "",
      item: "NTA UGC NET",
    },
    {
      link: "",
      item: "Bank Exams",
    },
  ];

  const studymaterial = [
    {
      link: "",
      item: "UPSC Study Material",
    },
    {
      link: "",
      item: "NEET UG Study Material",
    },
    {
      link: "",
      item: "CA Foundation Study Material",
    },
    {
      link: "",
      item: "JEE Study Material",
    },
    {
      link: "",
      item: "SSC Study Material",
    },
  ];

  return (
    <div>
      <div className="flex  justify-center bg-[#2A2B2D] text-white mt-10">
        <div className="w-[90%] lg:w-[65%] tablet:w-[85%]  flex justify-between flex-col ">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-[30%]  lg:px-5 pb-5  me-5">
              <div className="">
                <div className="">
                  <BrandLogo />
                </div>
                <p className="text-xs lg:text-sm my-5 text-gray-400">
                  UpMyPrep is India’s largest online learning platform. Download
                  our apps to start learning
                </p>
                <div className="flex items-center justify-start  my-5">
                  <img
                    src={require("../../../assets/Dexlabz/app-store.png")}
                    alt=""
                    className="w-[30%] md:w-[40%]"
                  />
                  <img
                    src={require("../../../assets/Dexlabz/PlayStore.png")}
                    alt=""
                    className="w-[30%] md:w-[40%]"
                  />
                </div>
              </div>
              <div className="flex flex-col item mt-16">
                <p className=" font-semibold text-sm tablet:text-sm lg:text-base mb-2">
                  Starting your preparation?
                </p>
                <p className="text-xs lg:text-sm mb-5 text-gray-400">
                  Call us and we will answer all your questions about learning
                  on UpMyPrep
                </p>
                <p className="flex items-center   font-mono cursor-pointer text-xs">
                  <BiSolidPhoneCall className="mx-2" /> Call +91 9876543210
                </p>
              </div>
            </div>

            <div className="w-full lg:w-[63%] tablet:w-[70%] flex flex-col md:flex-row py-5 justify-between pe-10 tablet:px-0 ">
              <div className="flex flex-row flex-wrap md:flex-col  items-start  tablet:px-0 justify-between">
                <div className="my-2">
                  <p className="text-base font-semibold">Company</p>

                  <ul className="mt-5">
                    {company.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate(data?.link)}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="my-2">
                  <p className="text-base font-semibold">Popular goals</p>

                  <ul className="mt-5">
                    {goals.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate("#")}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex flex-row flex-wrap md:flex-col justify-between items-start  tablet:px-0 ">
                <div className="my-2">
                  <p className="text-base font-semibold">Help & support</p>

                  <ul className="mt-5">
                    {HelpSupport.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate(data?.link)}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="my-2">
                  <p className="text-base font-semibold">Trending exams</p>

                  <ul className="mt-5">
                    {exams.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate("#")}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex flex-row flex-wrap md:flex-col justify-between items-start tablet:px-0 ">
                <div className="my-2">
                  <p className="text-base font-semibold">Products</p>

                  <ul className="mt-5">
                    {products.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate("#")}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="my-2">
                  <p className="text-base font-semibold">Study material</p>

                  <ul className="mt-5">
                    {studymaterial.map((data, index) => (
                      <li
                        key={index}
                        className="cursor-pointer text-xs lg:text-sm my-2 text-gray-400 hover:text-gray-100"
                        onClick={() => navigate("#")}
                      >
                        {data.item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-start  justify-between pt-10 pb-5 border-t border-gray-100 ">
            <div className="w-full text-center md:text-start">
              <p className="text-xs lg:text-sm text-gray-400">
                © 2023 Phynlabz Technology Pvt Ltd
              </p>
            </div>
            <div className="flex mb-10">
              <BiLogoFacebook className="cursor-pointer mx-2 text-xl text-gray-500 hover:text-gray-100" />
              <BiLogoYoutube className="cursor-pointer mx-2 text-xl text-gray-500 hover:text-gray-100" />
              <BiLogoTwitter className="cursor-pointer mx-2 text-xl text-gray-500 hover:text-gray-100" />
              <BiLogoInstagram className="cursor-pointer mx-2 text-xl text-gray-500 hover:text-gray-100" />
              <BiLogoLinkedin className="cursor-pointer mx-2 text-xl text-gray-500 hover:text-gray-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DarkFooter;
