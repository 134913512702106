import React, { useEffect, useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";

import { useNavigate } from "react-router-dom";
import { ApiConstants } from "../../../api/ApiConstants";
import axios, { HttpStatusCode } from "axios";

function Registration({
  email,
  setEmail,
  setShowLoginPopup,
  setShowJoinFreePopup,
  setIsOtpRequested,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState(null);
  const [password, setPassword] = useState("");

  console.log("registration wale page ka email : ", email);

  const navigate = useNavigate();

  const handleCompleteRegistration = async () => {
    if (
      firstName === undefined ||
      firstName === null ||
      firstName.length === 0
    ) {
      return;
    }

    var bodyObject = {
      profile: {
        firstName,
        lastName,
        mobile: parseInt(mobile),
      },
      email,
      password,
    };

    try {
      const { status } = await axios.put(
        ApiConstants.BASE_URL +
          "" +
          ApiConstants.UPDATE_USER_REGISTRATION_END_POINT,
        bodyObject
      );

      if (status === HttpStatusCode.Ok) {
        document.body.style.overflow = "scroll";
        setShowJoinFreePopup(false);
        setShowLoginPopup(true);
        setFirstName("");
        setLastName("");
        setPassword("");
        setEmail("");
        setIsOtpRequested(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    
    <div>
      <div className="w-full">
        <div className="flex flex-col md:flex-row mt">
          <div class="sm:col-span-3 grow mx-0 md:me-1">
            <div class="my-2">
              <input
                type="text"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 text-sm md:text-base"
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>

          <div class="sm:col-span-3 grow mx-0 md:ms-1">
            <div class="my-2">
              <input
                type="text"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 text-sm md:text-base"
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div class="sm:col-span-3">
          <div class="my-2">
            <input
              type="number"
              autocomplete="given-name"
              class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 text-sm md:text-base"
              placeholder="Mobile"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
        </div>

        <div class="sm:col-span-3">
          <div class="my-2">
            <input
              type="password"
              autocomplete="given-name"
              class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:text-sm sm:leading-6 text-sm md:text-base"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <CustomButton
          lable={"Create Account"}
          onClick={() => handleCompleteRegistration()}
          className={`w-full !mx-0 my-4 !text-base !py-2 bg-gray-700 text-white border-transparent hover:bg-gray-800`}
        />
      </div>
    </div>
  );
}

export default Registration;
