import React, { useState } from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { BsCheckCircle } from "react-icons/bs";
import { useGetLectureByCourseUidQuery } from "../../../api/ReduxToolkit";
import { IoIosArrowDown } from "react-icons/io";

function ScheduleStore({ currentCradData }) {
	const [showLectureBox, setShowLectureBox] = useState(false);

	// console.log("subStore Hero me current car data ", currentCradData?.courseUid);

	const { data: lectures, isLoading } = useGetLectureByCourseUidQuery(
		currentCradData?.courseUid
	);

	// console.log("aal lectures in store = ", lectures);

	return (
		<div>
			<div className="flex flex-col md:flex-row justify-between items-center md:items-start lg:p-5  ">
				<div className="w-full md:w-[30%] p-5">
					<p className="text-xl font-bold">Schedule</p>
					<ul className="text-xs md:text-sm mt-3">
						<li className="flex items-center my-1">
							<BsCheckCircle className="me-3 " /> Personalised daily learning
							plan
						</li>
						<li className="flex items-center my-1">
							<BsCheckCircle className="me-3 " /> Weekly tests to assess your
							preparation
						</li>
						<li className="flex items-center my-1">
							<BsCheckCircle className="me-3 " /> Practice questions to help you
							improve your concepts
						</li>
					</ul>
				</div>
				<div className="w-[90%] md:w-[60%] flex flex-col flex-wrap justify-between items-start">
					{/* ......................................... */}

					<div className="flex bg-white w-full lg:w-[45%] tablet:w-[60%] p-3 rounded-md shadow-lg my-3">
						<div className="">
							<img
								src={require("../../../assets/Dexlabz/full-day-class.png")}
								alt=""
							/>
						</div>
						<div className="mx-3">
							<p className=" text-gray-700 font-bold ">Full day classes</p>
						</div>
					</div>

					{/* ........................................................... */}

					<div className="w-full relative">
						<div
							className="w-full py-3 rounded-md font-medium  cursor-pointer px-3 shadow-lg border-none bg-white !mx-0 !translate-y-0 flex justify-between items-center"
							onClick={() => setShowLectureBox(!showLectureBox)}
						>
							<p className="">Free Lectures </p>
							<IoIosArrowDown
								className={`duration-300 ${
									showLectureBox ? "rotate-180" : "rotate-0"
								}`}
							/>
						</div>

						{/* {showLectureBox && ( */}
						<div
							className={`overflow-auto  lg:h-[25rem] flex flex-row lg:flex-col bg-white w-full absolute top-12 z-10 shadow-md rounded-b-lg duration-500 ${
								showLectureBox ? "block" : "hidden"
							}`}
						>
							{/* {similarClasses.map((card) => ( */}
							<div className=" flex flex-col lg:flex-row p-1 mx-1 lg:mx-5 my-2  cursor-pointer rounded-md   grow min-w-[8rem] tablet:min-w-[10rem]">
								<div className="w-full lg:w-[35%]">
									{/* <img src={card.img} alt="" className="rounded-md" /> */}
									<img
										src="https://edge.uacdn.net/static/thumbnail/batch/b3111a7a11a44932931159261be95a23.jpg?q=75&w=384&auto=format%2Ccompress"
										alt=""
										className="rounded-md"
									/>
								</div>
								<div className="ms-3">
									<p className="text-gray-600 font-semibold my-auto text-xs lg:text-base ">
										title
									</p>
									<p className="text-xs  my-1 text-xsm lg:text-sm">
										Description
									</p>
									{/* <p className="text-xs  my-auto text-xsm lg:text-xs">
                    {card.date} • {card.time}
                  </p> */}
								</div>
							</div>
							{/* ))} */}
						</div>
						{/* )} */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ScheduleStore;
