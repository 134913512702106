import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiConstants } from "../api/ApiConstants";

const initialState = {
  goals: [],
  loading: false,
  error: "",
  currentSubGoal: [],
};

const GoalsThunk = createAsyncThunk("goals/fetchGoals", () => {
  return axios
    .get(ApiConstants.BASE_URL + "" + ApiConstants.ALL_GOAL_END_POINT)
    .then((res) => {
      // console.log("goalThunk me goal api ka data : ", res);
      return res.data.msg;
    })
    .catch((err) => console.log(err));
});

const handleFillterGoal = (goalStateArray, id) => {
  const subArray = goalStateArray.filter((goal) => goal._id === id);
  return subArray?.[0]?.subgoal;
};

const goalsSlice = createSlice({
  name: "golesReducer",
  initialState,
  reducers: {
    setSubGoals(state, action) {
      state.currentSubGoal = handleFillterGoal(state.goals, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GoalsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GoalsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.goals = action.payload;
      state.error = "";
    });
    builder.addCase(GoalsThunk.rejected, (state, action) => {
      state.loading = false;
      state.goals = [];
      state.error = action.error.message;
    });
  },
});

export const { setSubGoals } = goalsSlice.actions;

export default goalsSlice.reducer;
export { GoalsThunk };
