import React from "react";
import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import DarkFooter from "../Footer/DarkFooter";

function RefundPolicy() {
  const userAccount = [
    {
      title: "A purchased course may be cancelled till 7 Days from purchase.",
    },
    {
      title:
        "If you need to cancel a purchase and request a refund, please send a mail to reproneet@gmail.com",
    },
    {
      title: "The email will be responded to within 2 working days.",
    },
    {
      title:
        "The entire refund will be issued if a valid reason for cancellation is found and the request is made within 7 Days of purchase.",
    },
    {
      title:
        "Refunds will not be issued and request will be rejected if the above is not fulfilled",
    },
  ];

  return (
    <div className="">
      <Toolbar />

      <div className="flex justify-center flex-col  items-center   ">
        <div className="w-[90%]  lg:w-[70%] tablet:w-[90%]  flex flex-col gap-10   tablet:py-3 items-start my-10 lg:px-10  ">
          <div className="flex justify-center  w-full text-2xl font-semibold  ">
            <p className="">Refund Policy </p>
          </div>{" "}
          <div className="flex flex-col gap-5  w-full   ">
            <ul className="list-disc px-5 mt-5 font-normal text-xs md:text-sm  flex flex-col gap-3 border-y-2 py-5">
              {userAccount?.map((items, index) => (
                <li key={index} className="">
                  {items?.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <DarkFooter />
    </div>
  );
}

export default RefundPolicy;
