import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Shared/CustomButton/CustomButton";

function OverTimePopup() {
	const navigate = useNavigate();
	return (
		<div className="w-full h-screen bg-[rgba(128,128,128,.8)] flex flex-col justify-center items-center fixed top-0 left-0 z-40">
			<div className="bg-white  w-[25rem]  h-[10rem]  p-5 relative rounded-2xl shadow-lg">
				<p className="my-5 text-base font-semibold">
					Click on OK to see the result
				</p>

				<p className="">
					<CustomButton
						lable={"OK"}
						className={`bg-green-500 hover:bg-green-600 text-white border-none !mx-0`}
						onClick={() => navigate("/")}
					/> 
				</p>
			</div>
		</div>
	);
}

export default OverTimePopup;
