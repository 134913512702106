import React, { useEffect } from "react";
import Lottie from "lottie-react";

import failed from "../../../assets/Dexlabz/Payment-failed.json";
import { useNavigate } from "react-router-dom";

function PaymentRejected() {
	const navigate = useNavigate();

	useEffect(() => {
		const delayedEffect = setTimeout(() => {
			navigate("/");
		}, 5000);

		return () => clearTimeout(delayedEffect);
	}, []);

	return (
		<div className="flex justify-center items-center h-screen ">
			<Lottie
				animationData={failed}
				loop={false}
				width={"50px"}
				height={"50px"}
				className="w-[400px] h-[400px] md:w-[500px] md:h-[500px]"
			/>
		</div>
	);
}

export default PaymentRejected;
