import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiConstants } from "../api/ApiConstants";

const initialState = {
  courses: [],
  loading: false,
  error: "",
};

const fetchCourses = createAsyncThunk("courses/fetchCourses", () => {
  return axios
    .get(`${ApiConstants.BASE_URL}/v1/api/courses/getallcourses?size=10&page=0`)
    .then((res) => {
      return res.data.responseData.courseResponse;
    });
});

const courseSlice = createSlice({
  name: "goalsReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.courses = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCourses.rejected, (state, action) => {
      state.loading = false;
      state.courses = [];
      state.error = action.error.message;
    });
  },
});

export default courseSlice.reducer;
export { fetchCourses };
