import { TiTick } from "react-icons/ti";
import scrollProfile from "../../../assets/Dexlabz/scrollProfile.gif";
import BatchDetails from "./BatchDetails";
import CourseCard from "./CourseCard";
import CustomButton from "../Shared/CustomButton/CustomButton";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const SubscribeAdvertisment = ({ title, thumb, SubjectWise, id }) => {
	const batchdesc = [
		"Full IIT JEE syllabus completion",
		"Topic-wise full syllabus notes",
		"Arvind Kalia",
	];
	const CourseCardData = [
		{
			CourseName: "Binomial Theorem-1",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-2",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-3",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-4",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-1",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-2",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-3",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-4",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-1",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-2",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-3",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
		{
			CourseName: "Binomial Theorem-4",
			image:
				"https://static.uacdn.net/thumbnail/course/v2/7A4158E3-D320-4DC9-B34C-15766198F726_special_class.png?q=75&w=640&auto=format%2Ccompress",
			Lecturer: "Ramesh Nethetla",
			Subject: "MATHEMATICS",
			Language: "English",
		},
	];
	return (
		<div
			className="flex flex-col justify-center items-center m-3 rounded-md tablet:w-[90%] w-[87%]"
			id={id}
		>
			<div className="flex flex-col md:flex-row justify-between md:items-center tablet:w-[90%] lg:w-[80%] py-5 border-b  mb-1">
				<span className="text-xl md:text-3xl  md:w-[30%] leading-tight font-semibold text-[#313639] m-3">
					Get subscription to start your preparation
				</span>
				<CustomButton
					className="bg-green-500 rounded-md text-white p-2 md:p-3 h-25 text-medium hover:bg-green-600 tablet:h-15 tablet:text-xs border-none"
					lable={"View subsrciption plans"}
				/>
			</div>
			<div className="flex flex-col md:flex-row justify-between items-center w-[80%] tablet:w-[100%] py-5">
				<div className="flex flex-col">
					<span className="text-xl 2xl:text-2xl leading-relaxed font-semibold pb-3 text-[#313639]">
						{title}
					</span>
					<div className="flex flex-col md:flex-row text-xs justify-start items-start">
						<span className="flex items-center p-1 ">
							<TiTick className="text-xl" /> Best for full syllabus preparation
						</span>

						<span className="flex items-center p-1">
							<TiTick className="text-xl" /> Live & recorded online classes
						</span>

						<span className="flex items-center p-1">
							<TiTick className="text-xl" /> Curated by best educators
						</span>
					</div>
				</div>
				<div className="hidden md:block text-center font-bold text-semibold px-5">
					<img className="w-40" src={scrollProfile} />
					<p>4.8K learners</p>
					<span className="text-xs font-light">explored a batch today</span>
				</div>
			</div>
			<div className="w-[100%] md:w-[80%] tablet:w-[100%] mx-3">
				<div className="flex justify-between items-center">
					<p className="text-sm md:text-lg font-semibold text-[#313639]  ">
						Recommended batch for you
					</p>
					<CustomButton
						className={
							"p-2 text-sm tablet:text-sm md:text-base  rounded mb-2 hover:bg-gray-200 border-none bg-gray-100"
						}
						lable={"see all"}
					/>
				</div>
				<div className="flex justify-center border-b pb-4 ">
					<BatchDetails
						thumbImage={thumb}
						recommended={true}
						batchTitle={"Nexus 3.O Batch for JEE Main and Advanced 2025"}
						batchDescription={batchdesc}
						batchIsLive={true}
					/>
				</div>
				<div className="mt-5 flex flex-col justify-center">
					<span className="text-sm md:text-xl font-semibold text-[#313639] p-3">
						Explore subject-wise courses
					</span>
					<div className="flex p-2 mb-3">
						{SubjectWise.map((text, index) => (
							<span
								className="flex items-center text-[15px] mx-1 text-gray-500"
								key={index}
							>
								<TiTick className="text-xl" /> {text}
							</span>
						))}
					</div>
					<div className="flex justify-between items-center">
						<span className="text-sm md:text-lg font-semibold p-3">
							Upcoming courses
						</span>
						<CustomButton
							className={
								"p-2 text-sm md:text-base  rounded mb-2 bg-gray-100 hover:bg-gray-200 border-none"
							}
							lable={"see all"}
						/>
					</div>
				</div>

				<Carousel
					additionalTransfrom={0}
					arrows
					autoPlaySpeed={3000}
					centerMode={false}
					className=" w-full self-center z-0"
					containerClass="container-with-dots"
					dotListClass=""
					draggable
					focusOnSelect={false}
					infinite={false}
					itemClass=""
					keyBoardControl
					pauseOnHover
					renderArrowsWhenDisabled={false}
					renderButtonGroupOutside={false}
					renderDotsOutside={false}
					responsive={{
						desktop: {
							breakpoint: {
								max: 3000,
								min: 1024,
							},
							items: 5.2,
							partialVisibilityGutter: 40,
						},
						mobile: {
							breakpoint: {
								max: 464,
								min: 0,
							},
							items: 1.1,
							partialVisibilityGutter: 30,
						},
						tablet: {
							breakpoint: {
								max: 1024,
								min: 464,
							},
							items: 3.2,
							partialVisibilityGutter: 30,
						},
					}}
					rewind={false}
					rewindWithAnimation={false}
					rtl={false}
					shouldResetAutoplay
					showDots={false}
					sliderClass=""
					slidesToSlide={1}
					swipeable
				>
					{CourseCardData.map((perData, index) => (
						<CourseCard data={perData} key={index} />
					))}
				</Carousel>

				{/* <div className="flex  overflow-x-scroll no-scrollbar  over w-full">
          {CourseCardData.map((perData, index) => (
            <CourseCard data={perData} key={index} />
          ))}
        </div> */}
			</div>
		</div>
	);
};

export default SubscribeAdvertisment;
