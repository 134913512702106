import React from "react";

function GetLearningContainer() {
	return (
    <div>
      <div className="flex justify-center ">
        <div className="w-[95%] md:w-[70%] flex flex-col-reverse tablet:w-[90%] md:flex-row ">
          <div className=" w-full md:w-[50%] flex items-start justify-center flex-col ">
            <p className="text-4xl font-bold my-5">Get the learning app</p>
            <p className="text-lg">
              Download lessons and learn anytime, anywhere with the UpMyPrep app
            </p>

            <div className="flex w-[90%] items-center justify-start  my-5 mt-10">
              <img
                src={require("../../../assets/Dexlabz/app-store.png")}
                alt=""
                className="w-[30%]"
              />
              <img
                src={require("../../../assets/Dexlabz/PlayStore.png")}
                alt=""
                className="w-[30%]"
              />
            </div>
          </div>

          <div className="w-full md:w-[50%]">
            <img
              src={require("../../../assets/Dexlabz/claasroom-1.png")}
              alt=""
              className=" tablet:w-[600px] xl:min-w-[300px] h-full rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetLearningContainer;
