import { GiElectric } from "react-icons/gi";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useEffect } from "react";
const TopEducator = ({ data }) => {

  // console.log("TopEducator me data : ",data)
  
 

  return (
    <div className="flex flex-col md:flex-row border justify-between items-center w-full 2sm:w-[48%] lg:w-[48%] tablet:w-[48%] my-1 md:m-1 rounded-md shadow-sm   ">
      <div className="relative w-[40%] md:w-[30%] tablet:w-[30%] mx-2 tablet:mx-1 my-1">
        <img
          src={data['image']}
          className=""
          alt=""
        />
        <div className="absolute left-1 bottom-1 tablet:top-24 tablet:left-0 md:left-3 md:top-36 bg-neutral-100 rounded-md shadow p-1 text-sm tablet:text-sm md:text-base font-semibold flex items-center text-yellow-600">
          <GiElectric />
          LEGEND
        </div>
      </div>
      <div className="w-full px-5  lg:w-[80%] tablet:w-[60%] md:self-start">
        <div className="flex flex-col justify-center md:justify-between">
          <p className="leading-tight font-bold text-sm tablet:text-base md:text-lg mt-5 tablet:mt-3">{data['name']}</p>
          <span className="leading-relaxed text-xsm tablet:text-xsm">
            {data['description']}
          </span>
          <div className="flex text-xs tablet:text-xs md:text-sm p-1 tablet:p-0 md:p-3 justify-between mt-2 md:mt-4 tablet:mt-3 tablet:w-[95%] w-[90%] md:w-[50%]">
            <div className="flex flex-col leading-tight">
              <span className="font-semibold">{data['watchtime']}</span>
              <p className="leading-relaxed">Watch Mins</p>
            </div>
            <div className="flex flex-col leading-tight">
              <span className="font-semibold">{data['followers']}</span>
              <span className="leading-relaxed">Followers</span>
            </div>
          </div>
          <div className="flex p-2 items-center w-[65%] tablet:w-[73%] md:w-[35%] justify-between hover:scale-105 ">
            <button className="self-start font-bold text-[11px] ">
              View Profile
            </button>
            <AiOutlineArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};
export default TopEducator;
