import React, { useContext, useEffect, useState } from "react";

import { BsArrowLeft } from "react-icons/bs";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { FaShare } from "react-icons/fa";

import "./SubStore.css";
import { BiSolidOffer } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SectionPopup from "./SectionPopup";
import { optimizeImage } from "../../../api/ApiConstants";
import useRazorpay from "react-razorpay";
import {
	useCreateOrderMutation,
	useGetEnrolledByUserIdQuery,
} from "../../../api/ReduxToolkit";
import { AuthContext } from "../../../providers/AuthProvider";
import BillSummary from "./BillSummary";
import { Utilits } from "../../../utils/utils";

import phynlaz_logo from "../../../assets/Dexlabz/phynlabz-logo.png";
import { useSelector } from "react-redux";

function SubStoreHero({
	setShowSubStore,
	currentCradData: cardData,
	checkAccess,
}) {
	const [showSectionPopup, setShowSectionPopup] = useState(false);
	const [showSummaryBox, setShowSummaryBox] = useState(false);
	const { userInfo, accessToken } = useContext(AuthContext);

	const { data: getMyEnrollments } = useGetEnrolledByUserIdQuery(userInfo?.uid);

	useEffect(() => {
		const id = document.getElementById("scrollable");
		if (showSectionPopup || showSummaryBox) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "scroll";
		}
		if (id) {
			id.style.overflow = "scroll";
		}
	}, [showSectionPopup, showSummaryBox]);

	const handleOpenPopup = () => {
		// navigate("/player", { state: { courseUid: cardData?.courseUid } });
		setShowSectionPopup(true);
	};

	const isAccessAllowed = () => {
		const isData = getMyEnrollments?.data?.find(
			(item) => item.courseId.courseUid === cardData?.courseUid
		);
		const access = isData !== null && isData !== undefined;
		checkAccess(access); 
		return access;
	};
	return (
		<div className=" px-3 lg:p-10  shadow-md rounded-xl  mt-5 md:mt-10 w-full ">
			<div className="flex items-center mb-5">
				<BsArrowLeft
					className="border p-1 rounded-md text-2xl mx-2 cursor-pointer"
					onClick={() => setShowSubStore(false)}
				/>
				{/* <p className="border p-1 rounded-md mx-2 px-3 text-sm cursor-pointer">
          {" "}
          NEET{" "}
        </p>
        <p className="border p-1 rounded-md mx-2 px-3 text-sm cursor-pointer">
          {" "}
          Batch{" "}
        </p> */}
			</div>

			{/* ................................. */}

			<div
				className={`flex flex-col lg:flex-row mb-10   w-full  ${
					isAccessAllowed() ? "border-none" : "border-b pb-10"
				}`}
			>
				<div className="w-full lg:w-[40%]">
					<img
						src={optimizeImage(
							cardData?.courseThumbnail?.[0]?.resourceId,
							300,
							170,
							60
						)}
						alt=""
						className="w-full rounded-md"
					/>
				</div>
				<div className="w-full lg:w-[65%] lg:mx-5 relative ">
					<p className="text-xs font-semibold my-2">
						<span className="border me-2 px-1  rounded">
							{cardData?.language?.[0]?.languageCode}
						</span>
						<span className="text-blue-500">{cardData?.courseType?.[0]}</span>
					</p>
					<p className="font-semibold  text-gray-800 text-2xl my-3 line-clamp-2">
						{cardData?.courseName}
					</p>
					<p className="font-semibold  text-orange-500 text-sm line-clamp-1">
						{cardData?.educatorInfo?.[0]?.educatorName}
					</p>
					<p className="text-sm text-gray-500 my-2 line-clamp-5">
						{cardData?.courseDescription}
					</p>
					{isAccessAllowed() && (
						<CustomButton
							onClick={() => {
								handleOpenPopup();
							}}
							lable={`Visit Classroom `}
							className={`   bg-green-500 border-green-500 text-white !px-5 !py-1.5 text-xl absolute top-3 right-3`}
						/>
					)}
				</div>
			</div>

			{/* ............................................... */}

			<div className="flex flex-col">
				{!isAccessAllowed() && (
					<div className="flex flex-col lg:flex-row justify-end  text-gray-700 ">
						{/* <div className=" my-2 ">
						<p className="text-xl font-medium text-gray-700">
							{cardData?.couponsEligible?.[0]?.couponName}
						</p>
						<p className="text-sm text-gray-500">
							{" "}
							{cardData?.couponsEligible?.[0]?.couponDescription}
						</p>
					</div> */}
						<div className="flex justify-between my-2 ">
							{cardData?.coursePricing?.[0]?.isDiscounted && (
								<div className="me-3">
									<p className="text-gray-500 text-sm ">
										<s className="me-3">
											₹ {cardData?.coursePricing?.[0]?.priceTag}
										</s>
										({cardData?.coursePricing?.[0]?.discountRate}% off)
									</p>
									<p className="text-base md:text-lg  font-semibold text-[#313639]">
										₹
										<span className="mx-2">
											{Math.round(
												parseInt(cardData?.coursePricing?.[0]?.priceTag) -
													(parseInt(cardData?.coursePricing?.[0]?.priceTag) *
														parseInt(
															cardData?.coursePricing?.[0]?.discountRate
														)) /
														100
											)}
										</span>
										only
									</p>
								</div>
							)}
							{!cardData?.coursePricing?.[0]?.isDiscounted && (
								<div className="me-3 flex items-end">
									<p className="text-base md:text-lg  font-semibold text-[#313639]">
										₹
										<span className="mx-2">
											{cardData?.coursePricing?.[0]?.priceTag}
										</span>
										only
									</p>
								</div>
							)}
							<div className="flex items-center">
								<CustomButton
									onClick={() => {
										setShowSummaryBox(true);
									}}
									lable={`Join batch `}
									className={`   bg-gray-700 border-gray-700 text-white !px-10 !py-3 text-xl`}
								/>

								<CustomButton
									lable={<FaShare />}
									className={`text-gray-700 border-gray-700 !py-3`}
								/>
							</div>
						</div>
					</div>
				)}
				{!isAccessAllowed() && (
					<div className="offerEndBgimage h-[100px] text-sm lg:text-base flex justify-center items-center text-orange-500 font-bold ">
						<BiSolidOffer className="me-3" />
						Offer ends on{" "}
						{Utilits.formatDate(cardData?.couponsEligible?.[0]?.validTill)}
					</div>
				)}

				{showSectionPopup && (
					<SectionPopup
						rightPopup={`animate-rightPopup`}
						setShowSectionPopup={setShowSectionPopup}
						courseData={cardData}
					/>
				)}

				{showSummaryBox && (
					<BillSummary
						rightPopup={`animate-rightPopup`}
						setShowSummaryBox={setShowSummaryBox}
						courseData={cardData}
					/>
				)}
			</div>
		</div>
	);
}

export default SubStoreHero;
