import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";

const ProductCarousel = () => {
	const [products, setProducts] = useState([]);

	const responsiveOptions = [
		{
			breakpoint: "1199px",
			numVisible: 3,
			numScroll: 1,
		},
		{
			breakpoint: "991px",
			numVisible: 2,
			numScroll: 1,
		},
		{
			breakpoint: "767px",
			numVisible: 1,
			numScroll: 1,
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("https://dummyjson.com/products");
				const data = await response.json();
				setProducts(data?.products);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, []);

	const itemTemplate = (product) => {
		return (
			<div className="product-card bg-green-300 h-[30rem]  mx-5">
				<img
					src={product?.images?.[0]}
					alt={product.name}
					className="h-[15rem] w-full"
				/>
				<h3>{product.brand}</h3>
				<p>{product.description}</p>
			</div>
		);
	};

	console.log("product", products);

	return (
		<div className="">
			<h2>Product Carousel</h2>
			<Carousel
				value={products}
				numVisible={4}
				numScroll={1}
				responsiveOptions={responsiveOptions}
				itemTemplate={itemTemplate}

				// responsiveOptions={[
				// 	{
				// 		breakpoint: "1500px",
				// 		numVisible: 4,
				// 		numScroll: 1,
				// 	},
				// 	{
				// 		breakpoint: "1024px",
				// 		numVisible: 3,
				// 		numScroll: 1,
				// 	},
				// 	{
				// 		breakpoint: "768px",
				// 		numVisible: 2,
				// 		numScroll: 1,
				// 	},
				// 	{
				// 		breakpoint: "480px",
				// 		numVisible: 1,
				// 		numScroll: 1,
				// 	},
				// ]}
			/>
		</div>
	);
};

export default ProductCarousel;
