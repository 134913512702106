import React from "react";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { BsCheckCircle } from "react-icons/bs";

function EducatorsStore({ currentCradData, activeGoalData }) {
	return (
		<div>
			<div className="flex flex-col md:flex-row justify-between items-start md:items-start lg:p-5  ">
				<div className="w-[30%] p-5">
					<p className="text-xl font-bold">Educators</p>
				</div>
				<div className="lg:w-[60%] flex flex-col flex-wrap justify-between items-center lg:items-start">
					{/* ......................................... */}

					<div className="flex bg-white w-[90%] tablet:w-[50%] lg:w-[45%]  rounded-md shadow-lg my-3">
						<div className=" w-[50%]">
							<img
								src={require("../../../assets/Dexlabz/awtar.png")}
								alt=""
								className=""
							/>
						</div>
						<div className="mx-3  w-[50%] py-2 relative">
							<div className=" ">
								<p className=" text-gray-700 font-bold ">
									{currentCradData?.educatorInfo?.[0]?.educatorName}
								</p>
								<p className=" text-gray-500  text-xsm">
									SCIENTIST, National Award Winner for Education, WORLD BOOK
								</p>
							</div>
							<div className="text-sm flex justify-between absolute bottom-0">
								<div className="mx-2">
									<p className=" text-gray-700 font-bold ">19M</p>
									<p className=" text-gray-500 text-xsm">Watch Mins</p>
								</div>
								<div className="mx-2">
									<p className=" text-gray-700 font-bold ">63K</p>
									<p className=" text-gray-500 text-xsm">Followers</p>
								</div>
							</div>
						</div>
					</div>

					{/* ........................................................... */}
				</div>
			</div>

			{/* ....................................................... */}

			<div className="flex flex-col md:flex-row justify-between items-start md:items-start lg:p-5  ">
				<div className="w-[30%] p-5">
					<p className="text-xl font-bold">Syllabus</p>
				</div>
				<div className="w-full md:w-[60%] flex flex-col flex-wrap justify-between items-center md:items-start ">
					{/* ......................................... */}

					<div className="flex bg-white w-[90%]  lg:w-[45%] py-5 rounded-md shadow-lg my-3 border-l-8 border-indigo-600 ">
						<div className="mx-3  w-[50%] py-2 relative">
							<div className=" ">
								<p className=" text-gray-700 font-bold ">
									{activeGoalData?.goalTitle}
								</p>
								<p className=" text-gray-500  text-xs">1 course</p>
							</div>
						</div>
					</div>

					<p className="text-gray-500  text-xs px-5 lg:px-0">
						Note - More courses will be added to each topic as the batch
						progresses
					</p>

					{/* ........................................................... */}
				</div>
			</div>
		</div>
	);
}

export default EducatorsStore;
