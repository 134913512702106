import React from "react";

function ClassroomCourseCard({ courseTitle, courseImageUrl, onClick }) {
	return (
		<div
			className="flex flex-col  min-w-[10rem] lg:min-w-[18rem] me-5  p-1 rounded-md border border-gray-50 m-1 hover:bg-gray-100 cursor-pointer min-h-[10rem]"
			onClick={onClick}
		>
			<div className="flex justify-center items-center ">
				<img src={courseImageUrl} className="rounded-md" />
			</div>
			<div className="flex flex-row flex-wrap justify-between items-start py-1">
				<span className=" md:p-1  text-xs md:text-sm leading-tight line-clamp-2">
					{courseTitle}
				</span>
				
			</div>
		</div>
	);
}

export default ClassroomCourseCard;
