import React from "react";
import { BiChevronRight } from "react-icons/bi";
import { FiChevronLeft } from "react-icons/fi";

const ButtonGroup = ({ next, previous }) => {
	return (
		<div className="carousel-button-group my-4  gap-4 flex justify-end items-center w-full">
			<button
				className="block p-1 border border-[#313639] text-[#313639] rounded-lg text-lg"
				onClick={() => previous()}
			>
				<FiChevronLeft />
			</button>
			<button
				className="block p-1 border border-[#313639] text-[#313639] rounded-lg text-lg"
				onClick={() => next()}
			>
				<BiChevronRight />
			</button>
		</div>
	);
};
export default ButtonGroup;
