import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import loginImg from "../../../assets/Dexlabz/hero-bg.svg";
import CustomButton from "../Shared/CustomButton/CustomButton";
import OTPInput from "react-otp-input";
import { AiFillCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { LoginThunk, clearError, setError } from "../../../redux/LoginSlice";
import { AuthContext } from "../../../providers/AuthProvider";

function Login({
	setShowLoginPopup,
	setShowJoinFreePopup,
	userInfo,	
	loginUser,
	isloading,
	hasError,
	setValidationError,
	errorMessage,
	clearError,
	className,
}) {
	const [showPasswordField, setShowPasswordField] = useState(false);
	const [isOtpRequested, setIsOtpRequested] = useState(false);
	const [showOtpBox, setShowOtpBox] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { login: setLoginToken } = useContext(AuthContext);

	if (userInfo?.serverResponse?.accessToken) {
		setLoginToken(userInfo?.serverResponse?.accessToken);
	}

	function ValidateEmail(mail) {
		if (
			/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
				mail
			)
		) {
			return true;
		}
		return false;
	}

	const handleLogin = async () => {
		if (email === undefined || email === null || email.length === 0) {
			setValidationError("Email is required");

			return;
		}

		if (!ValidateEmail(email)) {
			setValidationError("Email entered is incorrect");

			return;
		}

		if (password === undefined || password === null || password.length === 0) {
			setValidationError("Password is required");

			return;
		}

		loginUser({ email, password });
	};

	return (
		<div>
			<div className="bg-[rgba(128,128,128,.8)] w-full h-screen fixed top-0 left-0 z-50 rounded-md">
				<div
					className={`bg-white h-[35rem] md:h-[35rem] w-[85%]   lg:w-[70%] xl:w-[60%] 2xl:w-[50%] tablet:w-[50%] 2sm:w-[60%] absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto  rounded-lg flex items-start justify-between flex-col md:flex-row px-5 md:px-0 ${className}`}
				>
					<div className="md:w-[50%]  bg-blue-100 p-5 rounded-s-md h-[18rem] md:h-[35rem] flex flex-col hidden md:block tablet:hidden">
						<div className="text-3xl ">
							<AiFillCloseCircle
								className="cursor-pointer"
								onClick={() => setShowLoginPopup(false)}
							/>
						</div>

						<img src={loginImg} className="scale-90" alt="" />

						<div className=" px-5 text-gray-500 ">
							<p className="font-bold text-gray-600">
								Over 10 crore learners trust us for their preparation
							</p>
							<ul className="list-disc ps-5 mt-5">
								<li className="my-5">
									Learn from 2,000+ India’s top educators
								</li>
								<li className="my-5">
									Access 3,00,000+ live classes, test series, courses and more
									for free
								</li>
							</ul>
						</div>
					</div>

					<div className="w-full md:w-[50%]  tablet:w-full p-4 md:p-10 flex justify-start flex-col items-start ">
						<div className="text-3xl ms-auto md:hidden tablet:block">
							<AiFillCloseCircle
								className="cursor-pointer"
								onClick={() => setShowLoginPopup(false)}
							/>
						</div>

						<p className=" text-2xl tablet:text-3xl  text-center lg:text-3xl font-bold ">
							Log in
						</p>
						<p className=" text-sm tablet:text-sm tablet:ms-0 tablet:ps-0  text-center 2xl:text-sm mt-2 ">
							or{" "}
							<Link
								to=""
								className="text-red-500 font-bold"
								onClick={() => {
									setShowLoginPopup(false);
									setShowJoinFreePopup(true);
								}}
							>
								create a free account
							</Link>
						</p>

						<div className="my-5 w-full flex justify-center flex-col ">
							{hasError && (
								<p className="bg-red-200 text-red-800 p-2 my-3 rounded">
									{errorMessage}
								</p>
							)}

							<div className="flex items-start flex-col w-full ">
								<div className="sm:col-span-3 w-full">
									<div className="my-2">
										<input
											type="email"
											value={email}
											className="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-xs md:text-sm"
											placeholder="Email address"
											onChange={(e) => {
												setEmail(e.target.value);
												clearError();
											}}
										/>
									</div>
								</div>

								{!showPasswordField && (
									<p className="my-2 text-sm text-gray-600 ">
										We’ll send an OTP for verification
									</p>
								)}

								{showPasswordField && (
									<div className="sm:col-span-3 w-full">
										<div className="my-2">
											<input
												type="password"
												value={password}
												className="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-100 sm:leading-6 text-xs md:text-sm"
												placeholder="Password"
												onChange={(e) => {
													setPassword(e.target.value);
													clearError();
												}}
											/>
										</div>
									</div>
								)}
							</div>

							{showOtpBox && (
								<div className="flex justify-center my-5 ">
									<OTPInput
										// value={otp}
										// onChange={setOtp}
										inputStyle={{ width: "2.5rem" }}
										numInputs={6}
										renderSeparator={<span></span>}
										renderInput={(props) => (
											<input
												{...props}
												className="border border-black rounded-md px-2 mx-1  h-[3rem] "
											/>
										)}
									/>
								</div>
							)}

							{!isOtpRequested && !showPasswordField && (
								<CustomButton
									lable={"Request OTP"}
									className={`w-full !mx-0 my-4 !text-sm bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
									// onClick={() => handleGenerateOtp()}
								/>
							)}

							{isOtpRequested && !showPasswordField && (
								<CustomButton
									lable={"Verify OTP"}
									className={`w-full !mx-0 my-4 !text-sm bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
									// onClick={() => handleVerifyOtp()}
								/>
							)}

							{showPasswordField && (
								<CustomButton
									lable={"Login"}
									className={`w-full !mx-0 my-4 !text-sm bg-gray-700 text-white border-transparent hover:bg-gray-800 `}
									onClick={handleLogin}
								/>
							)}

							{!showPasswordField && (
								<p
									className=" text-xsm   text-center 2xl:text-xs mt-2 font-bold  underline decoration-dashed underline-offset-8 tracking-widest "
									onClick={() => setShowPasswordField(true)}
								>
									<Link to="" className="text-bold ">
										Continue with email & password
									</Link>
								</p>
							)}
							{showPasswordField && (
								<p
									className=" text-xsm   text-center 2xl:text-xs mt-2 font-bold  underline decoration-dashed underline-offset-8 tracking-widest "
									onClick={() => setShowPasswordField(false)}
								>
									<Link to="" className="text-bold ">
										Continue with email & OTP
									</Link>
								</p>
							)}

							<p className=" text-xsm   text-center 2xl:text-sm mt-2  absolute bottom-5  self-center w-full ">
								Having trouble?
								<br />
								Write us on{" "}
								<Link to="" className="text-bold font-normal">
									help@upmyprep.com
								</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		userInfo: state.loginReducer.userInfo,
		hasError: state.loginReducer.error,
		isloading: state.loginReducer.loading,
		errorMessage: state.loginReducer.errMsg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginUser: (body) => {
			dispatch(LoginThunk(body));
		},
		setValidationError: (err) => {
			dispatch(setError(err));
		},
		clearError: () => {
			dispatch(clearError());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
