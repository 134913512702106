import React from "react";

import { FaGifts, FaHeadset, FaUser } from "react-icons/fa";
import { PiArrowFatLineLeftFill } from "react-icons/pi";
import { AiTwotoneSetting } from "react-icons/ai";
import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
  BiLogoTwitter,
  BiLogoYoutube,
  BiSolidUser,
} from "react-icons/bi";

import { useNavigate } from "react-router-dom";

function ProfilePopup() {
  const navigate = useNavigate();

  const handleDeleteToken = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <div className="absolute top-16 z-20 bg-white text-gray-500 text-sm ms-auto flex flex-col justify-end  rounded-lg shadow-xl w-[16rem] animate-goalPopup">
      <ul className="">
        <li
          className={`flex items-center hover:bg-gray-100 py-2 my-1  font-semibold cursor-pointer px-2 pe-10 `}
          onClick={() => navigate("/user-profile")}
        >
          <BiSolidUser
            className={`mx-2 text-3xl p-1 bg-gray-200 rounded-full`}
          />{" "}
          Profile
        </li>
        <li
          className={`flex items-center hover:bg-gray-100 py-2 my-1  font-semibold cursor-pointer px-2 pe-10 `}
          onClick={() => navigate("/user-settings")}
        >
          <AiTwotoneSetting
            className={`mx-2 text-3xl p-1 bg-gray-200 rounded-full`}
          />{" "}
          Settings
        </li>
        <li
          className={`flex items-center hover:bg-gray-100 py-2 my-1  font-semibold cursor-pointer px-2 pe-10 `}
        >
          <FaHeadset className={`mx-2 text-3xl p-1 bg-gray-200 rounded-full`} />
          Report an issue
        </li>
        <li
          className={`flex items-center hover:bg-gray-100 py-2 my-1  font-semibold cursor-pointer px-2 pe-10 `}
          onClick={() => navigate("/refer")}
        >
          <FaGifts className={`mx-2 text-3xl p-1 bg-gray-200 rounded-full`} />
          Refer your friends
        </li>
        <li
          className={`flex items-center hover:bg-gray-100 py-2 my-1  font-semibold cursor-pointer px-2 pe-10 `}
          onClick={() => handleDeleteToken()}
        >
          <PiArrowFatLineLeftFill
            className={`mx-2 text-3xl p-1 bg-gray-200 rounded-full`}
          />
          Log out
        </li>
      </ul>

      <div className="px-3 ">
        <div className="flex flex-row flex-wrap text-xs border-t ">
          <p className="text-xs flex items-center flex-wrap  py-0">
            <span
              className="cursor-pointer hover:text-gray-800"
              onClick={() => navigate("/terms-conditions")}
            >
              Terms & Conditions
            </span>{" "}
            <b className="mx-2 text-3xl">•</b>
            <span
              className="cursor-pointer hover:text-gray-800"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>
          </p>
          <p className="text-xs flex items-center flex-wrap  py-0 -translate-y-3 ">
            <span className="cursor-pointer hover:text-gray-800 ">About</span>{" "}
            <b className="mx-2 text-3xl">•</b>
            <span className="cursor-pointer hover:text-gray-800">
              Careers
            </span>{" "}
            <b className="mx-2 text-3xl">•</b>
            <span className="cursor-pointer hover:text-gray-800">Blog</span>
          </p>
        </div>

        <div className="flex mb-5 ">
          <BiLogoFacebook
            className={`bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-1 text-2xl rounded me-4 cursor-pointer`}
          />
          <BiLogoYoutube
            className={`bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-1 text-2xl rounded me-4 cursor-pointer`}
          />
          <BiLogoTwitter
            className={`bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-1 text-2xl rounded me-4 cursor-pointer`}
          />
          <BiLogoInstagram
            className={`bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-1 text-2xl rounded me-4 cursor-pointer`}
          />
          <BiLogoLinkedin
            className={`bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-1 text-2xl rounded me-4 cursor-pointer`}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfilePopup;
