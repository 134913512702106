import React from "react";

import { AiOutlineSearch } from "react-icons/ai";
import { SiHomeassistant } from "react-icons/si";
import { SlChemistry } from "react-icons/sl";
import { FaStaffSnake } from "react-icons/fa6";
import { HiCurrencyRupee } from "react-icons/hi";

import CustomButton from "../Shared/CustomButton/CustomButton";

function GoalContainer() {
  const card = [
    {
      icon: <SiHomeassistant className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <SlChemistry className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <FaStaffSnake className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <HiCurrencyRupee className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <SiHomeassistant className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <SlChemistry className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <FaStaffSnake className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
    {
      icon: <HiCurrencyRupee className="text-3xl mb-5" />,
      heading: "UPSC CSE - GS",
    },
  ];

  return (
    <div>
      <div className=" flex justify-center my-10">
        <div className="w-[90%] mx-2 lg:w-[70%] 2sm:w-[80%]  flex justify-between flex-col">
          <div className="">
            <p className="text-xl md:text-3xl font-bold text-gray-800">
              Select your goal / exam
            </p>
            <p className="text-xsm my-1 md:text-base md:my-4">
              <span className="text-green-500">200+ exams</span> available for
              your preparation
            </p>
          </div>

          <div className="  w-full my-3">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-xl">
                  <AiOutlineSearch />
                </span>
              </div>
              <input
                type="text"
                name="price"
                id="price"
                className="block w-full rounded-md border-0 py-3 px-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gary-600 text-xsm md:text-sm sm:leading-6"
                placeholder="Type the goal / exam You're preparing for "
              ></input>
            </div>
          </div>

          <div className="my-5">
            <p className="text-2xl md:text-3xl font-bold">Popular goals</p>
          </div>

          <div className="flex flex-wrap">
            {card.map((item, index) => (
              <div
                className="border w-[45%] lg:w-[32%] xl:w-[24%] 2sm:w-[48%] tablet:w-[32%] tab mx-auto my-2 py-10 flex justify-center items-center flex-col font-bold text-sm rounded-xl hover:bg-slate-50 "
                key={index}
              >
                {item.icon}
                <p className="">{item.heading}</p>
              </div>
            ))}
          </div>

          <div className="">
            <CustomButton
              className={`hover:bg-slate-50 mt-5 md:mt-10 !text-xsm md:!text-xs`}
              lable={"see all goals (200+)"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoalContainer;
