import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useRef } from "react";
import { connect } from "react-redux";

const Slider = ({ id, activeThumbnails }) => {
	// console.log("slider me activeThumbnails ka data : ", activeThumbnails);

	const ref = useRef();
	const showNext = () => {
		ref.current.onClickNext();
	};

	const showRevious = () => {
		ref.current.onClickPrev();
	};

	return activeThumbnails?.length > 0 ? (
		<div
			className="flex items-center justify-center my-1 md:my-5 animate-blurIn"
			id={id}
		>
			<div className="absolute flex w-[87%] tablet:w-[80%] lg:w-[78%] justify-between mx-auto left-0 right-0 z-30 ">
				<button className="p-2 rounded-2xl" onClick={showRevious}>
					<img
						src={require("../../../assets/Dexlabz/prev-icon.png")}
						alt=""
						className="w-[50%] tablet:w-[75%] lg:w-[90%]"
					/>
				</button>
				<button className="p-2 rounded-2xl" onClick={showNext}>
					<img
						src={require("../../../assets/Dexlabz/next-icon.png")}
						alt=""
						className="w-[50%] tablet:w-[75%] lg:w-[90%]"
					/>
				</button>

				{/* <button className="p-2 rounded-2xl" onClick={showRevious}><AiFillLeftSquare className="text-gray-400 text-3xl md:text-5xl" /></button>
        <button className="p-2 rounded-2xl" onClick={showNext}><AiFillRightSquare className="text-gray-400 text-3xl md:text-5xl" /></button> */}
			</div>

			{/* <div className=""> */}
			<Carousel
				ref={ref}
				centerMode={true}
				showThumbs={false}
				infiniteLoop={true}
				dynamicHeight={false}
				showStatus={false}
				showArrows={false}
				autoPlay={true}
				showIndicators={false}
				className="rounded-md"
				centerSlidePercentage={75}
			>
				{activeThumbnails?.map((image, index) => (
					<img
						key={index}
						className=" md:rounded-xl w-[80%] tablet:w-[90%] h-[7rem] tablet:h-[15rem] md:h-[22rem] px-2 pe-5 md:pe-0 lg:px-16 tablet:px-5 rounded-md"
						src={image.url}
					/>
				))}
			</Carousel>
		</div>
	) : (
		<div></div>
	);
	// </div>
};

const mapStateToProps = (state) => {
	return {
		activeThumbnails:
			state?.userSelectGoalReducer?.userGoalInfo?.activeGoal?.options
				?.thumbnails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
