import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const isTokenExpired = (token) => {
    return Date.now() / 1000 > jwtDecode(token).exp;
  };
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (
      token !== null &&
      token !== undefined &&
      token !== "" &&
      !isTokenExpired(token)
    ) {
      setAccessToken(token);
      setUserInfo(jwtDecode(token)?.data);
    }
  }, []);

  const login = (token) => {
    setAccessToken(token);
    localStorage.setItem("token", token);
    setUserInfo(jwtDecode(token)?.data);
  };

  const logout = () => {
    setAccessToken(null);
    localStorage.removeItem("token");
    setUserInfo(null);
  };


  return (
    <AuthContext.Provider
      value={{ login, logout, accessToken, isLoading, userInfo }}
    >
      {children}
    </AuthContext.Provider>
  );
};
