import Toolbar from "../AfterLoginPages/Toolbar/Toolbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DarkFooter from '../Footer/DarkFooter';
const StoryData = [
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  {
    ProfileImage:
      "https://edge.uacdn.net/static/thumbnail/user/a40294d924c248abb2055b09ec19ef72.jpg?q=75&w=64&auto=format%2Ccompress",
    Name: "Hussain",
    Comments:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit rerum a soluta perferendis consectetur aperiam quo, consequatur ipsa adipisci unde eveniet doloremque amet aliquam nam sequi non. Nesciunt, ipsum eveniet.",
    Course: " IIT JEE ",
    LearningMins: " 16K learning mins ",
  },
  
];


const SuccessStories = () => {
  const handleScroll = (id) => {
    const item = document.getElementById(id);

    if (item) {
      item.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="flex justify-center flex-col">
      <div className="sticky top-0 z-50 bg-white">
        <Toolbar handleScroll={handleScroll} />
      </div>

      <div className="w-[95%] tablet:w-[80%] lg:w-[70%] self-center flex justify-center items-center flex-col my-10 px-3">
        <p className="text-xl md:text-4xl  lg:w-[40%] text-center font-bold mb-5 text-gray-700">
          UpMyPrep is the perfect place for your preparation
        </p>
        <p className="mx-5 md:mx-0 text-xs md:text-sm">
          Over <span className="text-green-500">10 crore </span>learners trust
          us for their preparation
        </p>
      </div>

      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=" w-[95%] tablet:w-[80%] lg:w-[70%] self-center "
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3.2,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1.1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2.2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {StoryData.map((story) => (
          <Story
            image={story.ProfileImage}
            comment={story.Comments}
            name={story.Name}
            course={story.Course}
            learningmin={story.LearningMins}
          />
        ))}
      </Carousel>

      <div className="">
        <DarkFooter />
      </div>
    </div>
  );
};
const Story = ({ image, name, comment, course, learningmin }) => {
  return (
    <div className="flex flex-col  m-2 rounded-md shadow-md bg-white px-5 py-3 relative h-[16rem]">
      <div className="flex justify-start items-center">
        <img src={image} className="rounded shadow" />
      </div>
      <p className="my-2   line-clamp-3  text-sm">{comment}</p>
      <div className=" flex flex-col justify-center items-start  absolute bottom-3">
        <p className="leading-tight text-lg font-semibold">{name}</p>
        <p className="text-xs mt-2 text-gray-500">
          {course}
          <span> : </span>
          {learningmin}
        </p>
      </div>
    </div>
  );
};

export default SuccessStories;
