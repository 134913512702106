import React, { useEffect } from "react";
import { useGetAllSectionsByCourseUidQuery } from "../../../api/ReduxToolkit";
import BrandLogo from "../Shared/BrandLogo/BrandLogo";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import processing from "../../../assets/Dexlabz/mentinence.json";
import search from "../../../assets/Dexlabz/searching.json";

function ChooseSection({ currentCourseData }) {
	const navigate = useNavigate();

	const { data: allSections } = useGetAllSectionsByCourseUidQuery(
		currentCourseData?.courseUid
	);

	const handleCardClick = (sectionData) => {
		navigate("/player", {
			state: {
				courseData: currentCourseData,
				sectionName: sectionData?.sectionName,
				sectionUid: sectionData?.sectionUid,
				sectionId: sectionData?._id,
			},
		});
	};
	return (
		<div className="">
			{currentCourseData?.courseName ? (
				<div className="">
					{allSections?.sections?.length > 0 ? (
						<div className="m-5 ">
							<p className="text-xl font-medium">
								{currentCourseData?.courseName}
							</p>
							<div className=" mt-5  flex flex-col justify-end items-end">
								{allSections?.sections?.map((sectionData, index) => (
									<div
										className="flex justify-between items-start shadow rounded-lg  h-[5rem] w-full    my-3 cursor-pointer "
										onClick={() => handleCardClick(sectionData)}
										key={index}
									>
										<div className=" w-[30%] h-full ">
											{sectionData?.Thumbnail ? (
												<img
													src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_0Xr9NGJgOcmUn3SKp_5iZg4vHWrOU5I_w&usqp=CAU"
													alt=""
													className="w-full h-[5.5rem]"
												/>
											) : (
												<div className="bg-neutral-200 flex justify-center items-center h-full rounded-md">
													<BrandLogo className={`w-[4rem] h-[4rem]`} />
												</div>
											)}
										</div>
										<div className=" w-[70%] px-5 py-2">
											<p className="font-medium text-[#313639] text-xs md:text-base line-clamp-1">
												{sectionData?.sectionName}
											</p>
											<p className="text-xs line-clamp-3 md:line-clamp-2 text-gray-400">
												{sectionData?.sectionDescription}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					) : (
						<div className=" w-full flex flex-col justify-center items-center my-auto h-[80vh] ">
							<Lottie
								animationData={processing}
								loop={true}
								className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] "
							/>
							<div className=" text-sm font-medium text-[#313639]">
								Work is going on under-processing
							</div>
						</div>
					)}
				</div>
			) : (
				<div className=" w-full flex flex-col justify-center items-center my-auto h-[80vh] ">
					<Lottie
						animationData={search}
						loop={true}
						className="w-[150px] h-[150px] md:w-[200px] md:h-[200px] "
					/>
					<div className=" text-sm font-medium text-[#313639] -translate-y-10">
						Please choose course !
					</div>
				</div>
			)}
		</div>
	);
}

export default ChooseSection;
