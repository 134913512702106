import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiConstants } from "./ApiConstants";

export const DashBoardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiConstants.BASE_URL,
  }),
  endpoints: (builder) => ({
    getEnrolledByUserId: builder.query({
      query: (userId) => `${ApiConstants.GET_ENROLLED_BY_USERID}/${userId}`,
    }),
    getStoreDataByActiveGoal: builder.query({
      query: (id) => `${ApiConstants.ACTIVE_GOAL_STORE}/${id}`,
    }),
    getAllSectionsByCourseUid: builder.query({
      query: (id) => `${ApiConstants.GET_ALL_SECTIONS_BY_COURSE_UID}/${id}`,
    }),
    getAllChaptersBySectionUid: builder.query({
      query: (id) => `${ApiConstants.GET_ALL_CHAPTER_BY_SECTION_UID}/${id}`,
    }),
    getAllLecturesByChapterUid: builder.query({
      query: (id) => `${ApiConstants.GET_ALL_LECTURE_BY_CHAPTER_UID}/${id}`,
    }),
    getLectureByCourseUid: builder.query({
      query: (courseUid) =>
        `${ApiConstants.GET_LECTURE_BY_COURSE_UID}/${courseUid}`,
    }),
    getLectureByLectureUid: builder.query({
      query: (lectureUid) =>
        `${ApiConstants.GET_LECTURE_BY_Lecture_UID}/${lectureUid}`,
    }),
    getQuestionByLectureUid: builder.query({
      query: (lectureUid) =>
        `${ApiConstants.GET_ALL_QUESTION_BY_LECTURE_ID}/${lectureUid}`,
    }),

    setActiveGoal: builder.query({
      query: (id) => `${ApiConstants.UPDATE_ACTIVE_GOAL}/${id}`,
    }),
    getVideoWatchDuration: builder.query({
      query: (userId) => `${ApiConstants.VIDEO_WATCH_DURATION}/${userId}`,
    }),

    updateActiveGoal: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.UPDATE_ACTIVE_GOAL}`,
        method: "PUT",
        headers: {
          "auth-token": token,
        },
        body,
      }),
    }),
    createOrder: builder.mutation({
      query: ({ body, token }) => ({
        url: `${ApiConstants.CREATE_ORDER}`,
        method: "POST",
        headers: {
          "auth-token": token,
        },
        body,
      }),
    }),
    validCoupon: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.VALIDATE_COUPON}`,
        method: "POST",
        body,
      }),
    }),
    paymentResponce: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.PAYMENT_RESPONCE}`,
        method: "POST",
        body,
      }),
    }),
    saveVideoProgress: builder.mutation({
      query: ({ body }) => ({
        url: `${ApiConstants.SAVE_VIDEO_PROGRESS}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
	useGetStoreDataByActiveGoalQuery,
	useUpdateActiveGoalMutation,
	useGetLectureByCourseUidQuery,
	useGetAllSectionsByCourseUidQuery,
	useGetAllChaptersBySectionUidQuery,
	useGetAllLecturesByChapterUidQuery,
	useGetLectureByLectureUidQuery,
	useCreateOrderMutation,
	useValidCouponMutation,
	usePaymentResponceMutation,
	useGetEnrolledByUserIdQuery,
	useSaveVideoProgressMutation,
	useGetVideoWatchDurationQuery,
	useGetQuestionByLectureUidQuery
} = DashBoardApi;
