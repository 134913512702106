import React from "react";
import { Utilits } from "../../../utils/utils";

function SubStoreAbout({ currentCradData: cardData, accessAllowed }) {
	return (
		<div>
			<div className="flex flex-col md:flex-row justify-between items-center md:items-start lg:p-5 ">
				<div className="w-full md:w-[30%] p-5">
					<p className="text-xl font-bold">About</p>
					<p className="text-sm mt-3">
						All the learning material you get when you join this batch
					</p>
				</div>
				<div className="w-[90%] md:w-[60%]  flex flex-col md:flex-row flex-wrap justify-between  items-center  md:items-start">
					{/* ......................................... */}

					<div className="flex bg-white w-full lg:w-[45%] tablet:w-[80%]  p-3 rounded-md shadow-lg my-3">
						<div className="">
							<img
								src={require("../../../assets/Dexlabz/livelassess.png")}
								alt=""
							/>
						</div>
						{/* <div className="mx-3">
							<p className="text-xs text-gray-500 ">Live classes</p>
							<p className="font-bold text-gray-700">30</p>
						</div> */}
						<div className="mx-3">
							<p className="text-xs text-gray-500 ">
								{cardData?.courseType?.[0]}
							</p>
							<p className="font-bold text-gray-700">50</p>
						</div>
					</div>

					{/* ........................................................... */}

					<div className="flex bg-white w-full lg:w-[45%] tablet:w-[80%]  p-3 rounded-md shadow-lg my-3">
						<div className="">
							<img
								src={require("../../../assets/Dexlabz/language.png")}
								alt=""
							/>
						</div>
						<div className="mx-3">
							<p className="text-xs text-gray-500 ">Language of teaching</p>
							<p className="font-bold text-gray-700">
								{cardData?.language?.[0]?.languageName}
							</p>
						</div>
					</div>

					{/* ........................................................... */}

					{accessAllowed && (
						<div className="flex bg-white w-full lg:w-[45%] tablet:w-[80%]  p-3 rounded-md shadow-lg my-3">
							<div className="">
								<img
									src={require("../../../assets/Dexlabz/access.png")}
									alt=""
								/>
							</div>
							<div className="mx-3">
								<p className="text-xs text-gray-500 ">Access ends on</p>
								<p className="font-bold text-gray-700">
									{Utilits.formatDate(
										cardData?.couponsEligible?.[0]?.validTill
									)}
								</p>
							</div>
						</div>
					)}

					{/* ........................................................... */}
				</div>
			</div>
		</div>
	);
}

export default SubStoreAbout;
