import React, { useState } from "react";
import { Utilits } from '../../../../utils/utils';
import parse from "html-react-parser";


const CustomRadioBtn = ({
	option,
	arrayIndex,
	setSelectedOption,
	selectedOption,
	options,
}) => {
	const handleOptionClick = (arrayIndex) => {
		const updatedOptions = options.map((option, i) => {
			if (i === arrayIndex) {
				return {
					...option,
					selected: !option?.selected,
				};
			}
			return option;
		});
		setSelectedOption(updatedOptions);
		// console.log("options = ", newOptions);
	};

	return (
		<div>
			<div
				onClick={() => handleOptionClick(arrayIndex)}
				className="flex items-center gap-2 cursor-pointer"
			>
				<input
					type="radio"
					name="options"
					checked={selectedOption?.[arrayIndex]?.selected === true}
					onChange={() => {}}
				/>
				<p className="">{parse(option?.content)}</p>
			</div>
		</div>
	);
};

export default CustomRadioBtn;
