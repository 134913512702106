import { Route, Routes } from "react-router-dom";
import PhysicsWallaTheme from "../pages/PhysicsWallaTheme";

export const SparkRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PhysicsWallaTheme/>} />
    </Routes>
  );
};
