import React from "react";

import { IoMdWifi } from "react-icons/io";
import CustomButton from "../Shared/CustomButton/CustomButton";
const BatchDetails = ({
  recommended,
  batchTitle,
  batchDescription,
  batchIsLive,
}) => {
  return (
    <div
      className="flex flex-col rounded-md items-start justify-between py-5   w-full"
      id="batchContainer"
    >
      <div className="flex flex-col md:flex-row items-start">
        <img
          src="https://edge.uacdn.net/static/thumbnail/batch/1e380bb933644aed9835e8a824beaf38.jpg?q=75&w=640&auto=format%2Ccompress"
          alt=""
          className="md:w-1/2 tablet:w-[55%] rounded-md"
        />
        <div className="flex flex-col justify-center w-[90%] my-2 mx-3">
          <div className="self-start text-xsm my-0">
            {recommended && (
              <div className="flex flex-col">
                <div className="bg-indigo-500 text-white rounded-md p-2">
                  Recommended
                </div>
                <span className="text-indigo-500 text-[9px] rounded-md p-2">
                  Top rated • Exceptional educators
                </span>
              </div>
            )}
          </div>
          <span className="leading-tight font-semibold text-[#313639] text-md md:text-lg 2xl:text-2xl">
            {batchTitle}
          </span>
          <div className="p-3">
            {batchDescription.map((text) => (
              <div className="flex items-center" key={text}>
                <div className="font-regular text-xs p-2">{text}</div>
              </div>
            ))}
          </div>
          <div className="flex justify-between self-start w-[90%] tablet:w-[100%] md:w-[80%]">
            <CustomButton
              className={
                "rounded-md bg-gray-800 hover:bg-gray-900 text-white !mx-0 !text-sm"
              }
              lable={"View batch details"}
            />
            {batchIsLive && (
              <div className="flex flex-col justify-center text-sm">
                <span>Batch is</span>
                <div className="flex justify-center items-center text-red-400">
                  <IoMdWifi />
                  <span> Live now</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
