import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import CustomButton from "../Shared/CustomButton/CustomButton";
const ScholarTest = () => {
  return (
    <div className="bg-[#FCF9E8] w-full mt-5 flex justify-center items-center">
      <div className="flex flex-col-reverse md:flex-row  w-[90%] lg:w-[70%] tablet:w-[90%]  md:justify-between justify-center items-center my-10">
        <div className="flex  flex-col justify-center  md:items-start items-center  mt-10 md:mt-0 text-center md:text-start">
          <div className="text-[#3D4952] font-bold md:text-2xl  text-lg md:w-[70%] w-full">
            <p className="">
              {" "}
              Take a test for free and win upto 50% scholarship
            </p>
          </div>
          <div className="flex flex-row my-5 justify-center  items-center  text-xs md:text-sm  w-full">
            <div className="flex flex-row text-[#3D4952]     items-center mx-2 md:mx-0 md:w-full">
              <BsCheckCircle className="me-2" />
              <p>Just 15 minutes</p>
            </div>
            <div className="flex flex-row text-[#3D4952] items-center  mx-2 md:mx-0 md:w-full">
              <BsCheckCircle className="me-2" />
              <p>15 quick questions</p>
            </div>
          </div>

          <CustomButton
            lable={"Attempt test now "}
            className={`bg-gray-600 text-white border-none !mx-0`}
          />
        </div>

        <div className="  flex flex-col justify-center items-center md:items-end md:justify-end  w-[80%] md:w-[40%] px-10">
          <img
            src={require("../../../assets/Dexlabz/ScholarTestImg.png")}
            className="h-50 w-70 md:px-5"
            alt="logo"
          />
          <div className="text-[#A0676B] font-bold text-xsm lg:text-sm flex flex-col justify-center items-center border-2  py-2 rounded-lg w-[20rem]] hidden md:block px-5">
            <p className="">171 learners have won</p>
            <p className="">₹15L worth of scholarships</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarTest;
